import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lead, PopulatedLead } from '@cgplib/model/Lead';
import { UserEntityService } from '@cgplib/services/user-entity.service';
import { environment } from '../../../environments/environment';
import { ResultData } from '../../model/Shared';

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  constructor(private http: HttpClient, private user: UserEntityService) {
    this.user.setUser();
  }

  fetchNotOpenedLeads() {
    return this.http.get<ResultData<Lead>>(
      `${environment.API_URL}/leads/not-opened`
    );
  }

  fetchOpenedLeads(search: string, page: number = 1, rows: number = 10) {
    return this.http.get<ResultData<Lead>>(
      `${environment.API_URL}/leads/opened/${search}?page=${page}&rows=${rows}`
    );
  }

  markLeadAsOpen(idLead: string) {
    return this.http.put<{ success: boolean }>(
      `${environment.API_URL}/leads/set-open/${idLead}`,
      {}
    );
  }

  markLeadAsConverted(idLead: string) {
    return this.http.put<{ success: boolean }>(
      `${environment.API_URL}/leads/set-converted/${idLead}`,
      {}
    );
  }

  fetchVerifiedLeads(search: string, page: number = 1, rows: number = 10) {
    return this.http.get<ResultData<PopulatedLead>>(
      `${environment.API_URL}/leads/admin/verified/${search}?page=${page}&rows=${rows}`
    );
  }

  fetchNotVerifiedLeads(search: string, page: number = 1, rows: number = 10) {
    return this.http.get<ResultData<PopulatedLead>>(
      `${environment.API_URL}/leads/admin/not-verified/${search}?page=${page}&rows=${rows}`
    );
  }

  fetchReportedLeads(search: string, page: number = 1, rows: number = 10){
    return this.http.get<ResultData<PopulatedLead>>(
      `${environment.API_URL}/leads/admin/reported/${search}?page=${page}&rows=${rows}`
    );
  }

  markLeadAsCorrupted(idLead: string, message: string) {
    return this.http.put<{ success: boolean }>(
      `${environment.API_URL}/leads/set-corrupted/${idLead}`, {message}
    )
  }

  updateReportedLead(idLead: string, data: any, ) {
    return this.http.put(`${environment.API_URL}/leads/admin/update/${idLead}`, data);
  }
}
