<ng-template #email let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Mot de passe oublié</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="col-12">
        <label for="login" class="form-label">Adresse mail</label>
        <input
          type="email"
          [formControl]="emailInput"
          class="form-control"
          [class.is-invalid]="
            validatorsService.hasRequiredError(emailInput) ||
            validatorsService.hasMailError(emailInput)
          "
        />
        <div
          class="invalid-feedback"
          *ngIf="validatorsService.hasRequiredError(emailInput)"
        >
          Ce champ est obligatoire.
        </div>
        <div
          class="invalid-feedback"
          *ngIf="validatorsService.hasMailError(emailInput)"
        >
          Le format est incorrect.
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      (click)="modal.close()"
    >
      Annuler
    </button>
    <button
      type="button"
      class="btn btn-lg btn-primary"
      [disabled]="emailInput.invalid"
      (click)="moveToSuccessModal()"
    >
      Valider
    </button>
  </div>
</ng-template>

<ng-template #waiting let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Mot de passe oublié</h4>
  </div>
  <div class="modal-body">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>
</ng-template>

<ng-template #success let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Mot de passe oublié</h4>
  </div>
  <div class="modal-body">
    <p>
      Si vous êtes enregistré vous allez recevoir un mail de réinitialisation de
      mot de passe
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      (click)="modal.close()"
    >
      Fermer
    </button>
  </div>
</ng-template>
