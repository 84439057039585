import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEntityService } from '@cgplib/services/user-entity.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient, private user: UserEntityService) {
    this.user.setUser();
  }

  fetchProfileInfo() {
    return this.http.get<{ firstName: string; lastName: string; mail: string }>(
      `${environment.API_URL}/users/profile`
    );
  }

  updateProfile(infos: { fistName: string; lastname: string; mail: string }) {
    return this.http.put(`${environment.API_URL}/users/profile`, infos);
  }

  updatePwd(infos: { oldPwd: string; newPwd: string; confirm: string }) {
    return this.http.put(`${environment.API_URL}/users/update-pwd`, {
      oldPwd: infos.oldPwd,
      newPwd: infos.newPwd,
    });
  }
}