<header id="header" class="header fixed-top d-flex align-items-center">
  <div class="d-flex align-items-center justify-content-between">
    <a routerLink="/" class="logo d-flex align-items-center">
      <img src="assets/theme/img/logo.png" alt="" />
      <span class="d-none d-lg-block">Mon Courtier & Moi</span>
    </a>
    <i class="bi bi-list toggle-sidebar-btn" (click)="toggleSideBar()"></i>
  </div>

  <nav class="header-nav ms-auto">
    <ul class="d-flex align-items-center">
      <li class="nav-item pe-3">
        <div ngbDropdown class="nav-link nav-profile pe-0 pointer">
          <span
            class="ps-2"
            id="dropdownProfile"
            ngbDropdownToggle
            *ngIf="this.userProfile"
            >{{ this.userProfile.firstName[0] }}.
            {{ this.userProfile.lastName }}</span
          >
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownProfile"
            class="profile"
          >
            <div class="dropdown-header" *ngIf="this.userProfile">
              <h6>
                {{ this.userProfile.firstName }} {{ this.userProfile.lastName }}
              </h6>
            </div>
            <hr class="dropdown-divider" />
            <a
              ngbDropdownItem
              class="dropdown-item d-flex align-items-center"
              routerLink="/profile"
            >
              <i class="bi bi-person"></i>
              <span class="dropdown-link-text">Mon profil</span>
            </a>
            <hr class="dropdown-divider" />
            <a
              ngbDropdownItem
              class="dropdown-item d-flex align-items-center"
              routerLink="/logout"
            >
              <i class="bi bi-box-arrow-right"></i>
              <span class="dropdown-link-text" routerLink="/logout"
                >Déconnexion</span
              >
            </a>
            <hr class="dropdown-divider" />
          </div>
        </div>
      </li>
    </ul>
  </nav>
</header>

<aside id="sidebar" class="sidebar">
  <ul class="sidebar-nav" id="sidebar-nav">
    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'dashboard'"
        routerLink="/admin/dashboard"
      >
        <i class="bi bi-grid"></i>
        <span>Dashboard</span>
      </a>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'memberships'"
        routerLink="/admin/memberships"
      >
        <i class="bi bi-card-checklist"></i>
        <span>Abonnements</span>
      </a>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'company'"
        data-bs-target="#company-nav"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="this.activeLink === 'company'"
      >
        <i class="bi bi-building"></i>
        <span>Entreprises</span><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="company-nav"
        class="nav-content collapse"
        [class.show]="this.activeLink === 'company'"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a
            routerLink="/admin/company/all"
            [class.active]="this.activeDropdown === 'activate'"
          >
            <i class="bi bi-circle"></i><span>Entreprises actives</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/admin/company/deactivated"
            [class.active]="this.activeDropdown === 'deactivated'"
          >
            <i class="bi bi-circle"></i><span>Entreprises innactives</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'leads'"
        data-bs-target="#leads-nav"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="this.activeLink === 'leads'"
      >
        <i class="bi bi-person-plus"></i>
        <span>Leads</span><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="leads-nav"
        class="nav-content collapse"
        [class.show]="this.activeLink === 'leads'"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a
            routerLink="/admin/leads/verified"
            [class.active]="this.activeDropdown === 'verified'"
          >
            <i class="bi bi-circle"></i><span>Leads vérifiés</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/admin/leads/not-verified"
            [class.active]="this.activeDropdown === 'not-verified'"
          >
            <i class="bi bi-circle"></i><span>Leads non vérifiés</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/admin/leads/reported"
            [class.active]="this.activeDropdown === 'reported'"
          >
            <i class="bi bi-circle"></i><span>Leads signalés</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <span
        class="nav-link"
        [class.collapsed]="this.activeLink !== 'articles'"
        data-bs-target="#articles-nav"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="this.activeLink === 'articles'"
      >
        <i class="bi bi-newspaper"></i><span>Articles</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </span>
      <ul
        id="articles-nav"
        class="nav-content collapse"
        [class.show]="this.activeLink === 'articles'"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a
            routerLink="/admin/articles/waiting"
            [class.active]="this.activeDropdown === 'waiting'"
          >
            <i class="bi bi-circle"></i><span>Articles à valider</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/admin/articles/all"
            [class.active]="this.activeDropdown === 'all'"
          >
            <i class="bi bi-circle"></i><span>Tous les articles</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'simulators'"
        data-bs-target="#simulators-nav"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="this.activeLink === 'simulators'"
      >
        <i class="bi bi-file-earmark-bar-graph"></i>
        <span>Simulations</span><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="simulators-nav"
        class="nav-content collapse"
        [class.show]="this.activeLink === 'simulators'"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a
            routerLink="/admin/simulators/verified"
            [class.active]="this.activeDropdown === 'verified'"
          >
            <i class="bi bi-circle"></i><span>Simulations vérifiées</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/admin/simulators/not-verified"
            [class.active]="this.activeDropdown === 'not-verified'"
          >
            <i class="bi bi-circle"></i><span>Simulations non vérifiées</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'contact'"
        data-bs-target="#contact-nav"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="this.activeLink === 'contact'"
      >
        <i class="bi bi-envelope"></i>
        <span>Demandes de contact</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="contact-nav"
        class="nav-content collapse"
        [class.show]="this.activeLink === 'contact'"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a
            routerLink="/admin/contact/pending"
            [class.active]="this.activeDropdown === 'pending'"
          >
            <i class="bi bi-circle"></i><span>En cours</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/admin/contact/closed"
            [class.active]="this.activeDropdown === 'closed'"
          >
            <i class="bi bi-circle"></i><span>Fermées</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'profile'"
        routerLink="/admin/profile"
      >
        <i class="bi bi-person"></i>
        <span>Profil</span>
      </a>
    </li>
  </ul>
</aside>
