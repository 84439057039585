<Navbar activeLink="profile"></Navbar>

<main id="main" class="main">
  <div class="pagetitle">
    <h1>Mon profil</h1>
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/admin/dashboard">Accueil</a></li>
        <li class="breadcrumb-item">Profil</li>
        <li class="breadcrumb-item active">Mes informations</li>
      </ol>
    </nav>
  </div>
  <!-- End Page Title -->

  <section class="section profile" *ngIf="this.infos">
    <div class="row">
      <div class="col-xl-3">
        <div class="card">
          <div
            class="card-body profile-card pt-4 d-flex flex-column align-items-center"
          >
            <img
              src="assets/img/profile-icon.png"
              alt="Profile"
              class="rounded-circle"
            />
            <h2>{{ infos.firstName }} {{ infos.lastName }}</h2>
          </div>
        </div>
      </div>
      
      <div class="col-xl-9">
        <div class="card">
          <div class="card-body pt-3">
            <!-- Bordered Tabs -->
            <ul class="nav nav-tabs nav-tabs-bordered">
              <li class="nav-item">
                <button
                  class="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-overview"
                >
                  Apercu
                </button>
              </li>

              <li class="nav-item">
                <button
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-edit"
                >
                  Modifier mes infos
                </button>
              </li>

              <li class="nav-item">
                <button
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-change-password"
                >
                  Modifier mon mot de passe
                </button>
              </li>
            </ul>
            <div class="tab-content pt-2">
              <div
                class="tab-pane fade show active profile-overview"
                id="profile-overview"
              >
                <h5 class="card-title">Détails du profil</h5>

                <div class="row">
                  <div class="col-lg-3 col-md-4 label">Nom complet</div>
                  <div class="col-lg-9 col-md-8">
                    {{ infos.firstName }} {{ infos.lastName }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-3 col-md-4 label">Email</div>
                  <div class="col-lg-9 col-md-8">{{ infos.mail }}</div>
                </div>
              </div>

              <div class="tab-pane fade profile-edit pt-3" id="profile-edit">
                <!-- Profile Edit Form -->
                <form [formGroup]="profileForm">
                  <div class="row mb-3">
                    <label
                      for="fullName"
                      class="col-md-4 col-lg-3 col-form-label"
                      >Prénom</label
                    >
                    <div class="col-md-8 col-lg-9">
                      <input
                        formControlName="firstName"
                        type="text"
                        class="form-control"
                        id="firstName"
                        [attr.placeholder]="infos.firstName"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label
                      for="fullName"
                      class="col-md-4 col-lg-3 col-form-label"
                      >Nom</label
                    >
                    <div class="col-md-8 col-lg-9">
                      <input
                        formControlName="lastName"
                        type="text"
                        class="form-control"
                        id="lastName"
                        [attr.placeholder]="infos.lastName"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="Email" class="col-md-4 col-lg-3 col-form-label"
                      >Email</label
                    >
                    <div class="col-md-8 col-lg-9">
                      <input
                        formControlName="mail"
                        type="email"
                        class="form-control"
                        id="Email"
                        [attr.placeholder]="infos.mail"
                        [class.is-invalid]="
                          validatorsService.hasMailError(
                            this.profileForm.get('mail')
                          )
                        "
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          validatorsService.hasMailError(
                            this.profileForm.get('mail')
                          )
                        "
                      >
                        Le format est incorrect.
                      </div>
                    </div>
                  </div>

                  <div class="text-end">
                    <button
                      [disabled]="isProfileFormEmpty()"
                      type="button"
                      class="btn btn-primary"
                      (click)="updateProfile()"
                    >
                      Sauvegarder
                    </button>
                  </div>
                </form>
                <!-- End Profile Edit Form -->
              </div>

              <div class="tab-pane fade pt-3" id="profile-change-password">
                <!-- Change Password Form -->
                <form [formGroup]="passwordForm">
                  <div class="row mb-3">
                    <label
                      for="currentPassword"
                      class="col-md-4 col-lg-3 col-form-label"
                      >Mot de passe actuel</label
                    >
                    <div class="col-md-8 col-lg-9">
                      <div class="input-group">
                        <input
                          [type]="showOldPwdText ? 'text' : 'password'"
                          class="form-control"
                          formControlName="oldPwd"
                          (input)="updatePwdErrors($event)"
                          [class.is-invalid]="
                            validatorsService.hasRequiredError(
                              this.passwordForm.get('oldPwd')
                            ) ||
                            validatorsService.hasFormatPwdError(
                              this.passwordForm.get('oldPwd')
                            )
                          "
                        />
                        <button
                          class="btn btn-link-secondary"
                          type="button"
                          (click)="showOldPwd()"
                        >
                          <i
                            class="bi"
                            [class.bi-eye-slash]="showOldPwdText"
                            [class.bi-eye]="!showOldPwdText"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label
                      for="newPassword"
                      class="col-md-4 col-lg-3 col-form-label"
                      >Nouveau mot de passe</label
                    >
                    <div class="col-md-8 col-lg-9">
                      <div class="input-group">
                        <input
                          [type]="showNewPwdText ? 'text' : 'password'"
                          class="form-control"
                          formControlName="newPwd"
                          (input)="updatePwdErrors($event)"
                          [class.is-invalid]="
                            validatorsService.hasRequiredError(
                              this.passwordForm.get('newPwd')
                            ) ||
                            validatorsService.hasFormatPwdError(
                              this.passwordForm.get('newPwd')
                            )
                          "
                        />
                        <button
                          class="btn btn-link-secondary"
                          type="button"
                          (click)="showNewPwd()"
                        >
                          <i
                            class="bi"
                            [class.bi-eye-slash]="showNewPwdText"
                            [class.bi-eye]="!showNewPwdText"
                          ></i>
                        </button>
                      </div>
                      <div
                        class="invalid-msg"
                        [class.text-danger]="
                          validatorsService.hasFormatPwdError(
                            this.passwordForm.get('newPwd')
                          )
                        "
                      >
                        Votre mot de passe doit contenir :
                        <ul>
                          <li [class.text-success]="pwdMatch.length">
                            Au moins 10 caractères
                          </li>
                          <li [class.text-success]="pwdMatch.maj">
                            Au moins 1 majuscule
                          </li>
                          <li [class.text-success]="pwdMatch.num">
                            Au moins 1 chiffre
                          </li>
                          <li [class.text-success]="pwdMatch.special">
                            Au moins 1 caractère spécial (* . ! @ $ % ^ & : ; ,
                            . ?)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label
                      for="renewPassword"
                      class="col-md-4 col-lg-3 col-form-label"
                      >Confirmer le mot de passe
                    </label>
                    <div class="col-md-8 col-lg-9">
                      <div class="input-group">
                        <input
                          [type]="showConfirmText ? 'text' : 'password'"
                          class="form-control"
                          formControlName="confirm"
                          (input)="updatePwdErrors($event)"
                          [class.is-invalid]="
                            validatorsService.hasRequiredError(
                              this.passwordForm.get('confirm')
                            ) ||
                            validatorsService.hasConfirmError(this.passwordForm)
                          "
                        />
                        <button
                          class="btn btn-link-secondary"
                          type="button"
                          (click)="showConfirm()"
                        >
                          <i
                            class="bi"
                            [class.bi-eye-slash]="showNewPwdText"
                            [class.bi-eye]="!showNewPwdText"
                          ></i>
                        </button>
                      </div>
                      <div
                        class="text-danger invalid-msg"
                        *ngIf="
                          validatorsService.hasConfirmError(this.passwordForm)
                        "
                      >
                        Les mots de passe ne correspondent pas.
                      </div>
                    </div>
                  </div>

                  <div class="text-end">
                    <button
                      type="button"
                      (click)="updatePassword()"
                      class="btn btn-primary"
                    >
                      Modifier
                    </button>
                  </div>
                </form>
                <!-- End Change Password Form -->
              </div>
            </div>
            <!-- End Bordered Tabs -->
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<!-- End #main -->
