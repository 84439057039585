import { Component, OnInit } from '@angular/core';
import { UserEntityService } from '../services/user-entity.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(private user: UserEntityService, private router: Router) {
    this.user.setUser()
  }

  redirect() {
    if(!this.user.isLogedIn()) {
      this.router.navigate(['login'])
      return;
    }
    if(this.user.isUserCompany()) {
      this.router.navigate(['cgp', 'dashboard'])
      return;
    }
    if(this.user.isUserAdmin() || this.user.isUserModerator()) {
      this.router.navigate(['admin', 'dashboard'])
      return;
    }


  }
}
