import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../services/registration.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './validation-mail.component.html',
  styleUrls: ['./validation-mail.component.scss']
})
export class ValidationMailComponent implements OnInit {

  success: boolean = false;
  loading: boolean = true;

  id: string = "";

  constructor(
    private route: ActivatedRoute,
    private service: RegistrationService,
    private toasterService: ToasterService,
    
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.service.activateUser(this.id).subscribe({
      next: () => {
        this.loading = false;
        this.success = true;
      }, error: (err) => {
        this.toasterService.showError(err.error.message);
      }
    })
  }

}
