import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationComponent } from './registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedRoutingModule } from '../shared/shared-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CgpRoutingModule } from '../cgp/cgp-routing.module';
import { ValidationMailComponent } from './validation-mail/validation-mail.component';


@NgModule({
  declarations: [
    RegistrationComponent,
    ValidationMailComponent
  ],
  imports: [
    CommonModule,
    RegistrationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CgpRoutingModule,
    SharedModule,
    SharedRoutingModule,
    NgbModule,
    NgApexchartsModule,
    EditorModule,
  ]
})
export class RegistrationModule { }
