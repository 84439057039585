import { Injectable } from '@angular/core';
import {
  AbstractControl,
  Form,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  public PWD_REGEX: RegExp = new RegExp(
    '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&:;,.?]).{10,}'
  );
  public PWD_LENGTH_REGEX: RegExp = new RegExp('.{10,}');
  public PWD_MAJ_REGEX: RegExp = new RegExp('(?=.*[A-Z])');
  public PWD_NUM_REGEX: RegExp = new RegExp('(?=.*[0-9])');
  public PWD_SPECIAL_REGEX: RegExp = new RegExp('(?=.*[*.!@$%^&:;,.?])');
  constructor() {}

  homePhoneNumberValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return null;
    if(ctl.value.countryCode !== "FR") return null
    const regex: RegExp = new RegExp(/\+33[1-2-3-4-5][0-9]{8}/);
    const tel: string = ctl.value.e164Number;
    const valid: boolean = tel.match(regex) === null;
    if (valid) return { wrongTelFormat: valid };
    return null;
  }

  mobilePhoneNumberValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return null;
    if(ctl.value.countryCode !== "FR") return null
    const regex: RegExp = new RegExp(/\+33[6-7][0-9]{8}/);
    const tel: string = ctl.value.e164Number;
    const valid: boolean = tel.match(regex) === null;
    if (valid) return { wrongTelFormat: valid };
    return null;
  }

  pwdFormatValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return { required: true };
    const pwd: string = ctl.value;
    const valid: boolean =
      pwd.match(
        new RegExp(
          '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&:;,.?]).{10,}'
        )
      ) === null;
    if (valid) return { wrongPwdFormat: valid };
    return null;
  }

  confirmPwdValidator(pwd: string): ValidatorFn {
    return (ctl: AbstractControl): ValidationErrors | null => {
      if (!ctl) return null;
      if (!ctl.value) return { required: true };
      if (ctl.value !== pwd) return { confirm: true };
      return null;
    };
  }

  matchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }

  consentValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return { required: true };
    return null;
  }

  categoriesValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    const fa: FormArray = ctl as FormArray;
    if (fa.length < 1) return { required: true };
    return null;
  }

  tooMuchKeywordsValidator(limit: number): ValidatorFn {
    return (ctl: AbstractControl): ValidationErrors | null => {
      if (!ctl) return null;
      const fa: FormArray = ctl as FormArray;
      if (fa.length > limit) return { tooMuchKeywords: true };
      return null;
    };
  }

  keywordLengthValidator(limit: number): ValidatorFn {
    return (ctl: AbstractControl): ValidationErrors | null => {
      if (!ctl) return null;
      const fg: FormGroup = ctl as FormGroup;
      if (fg.value && ctl.value.length > limit) return { keywordSize: true };
      return null;
    };
  }

  greaterDateValidator(dateParam: Date | undefined): ValidatorFn {
    return (ctl: AbstractControl): { [key: string]: any } | null => {
      const inputDate = new Date(ctl.value);
      const date = dateParam ? dateParam : new Date();
      if (inputDate.getTime() <= date.getTime()) {
        return { greaterDate: true }; // La date est inférieure ou égale à la date paramètre
      }
  
      return null; // La date est supérieure à la date paramètre
    };
  }
  // Forms error getters
  hasRequiredError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('required');
    return false;
  }

  hasMailError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('email');
    return false;
  }

  hasWrongNumberFormatError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.hasError('wrongTelFormat');
    return false;
  }

  hasFormatPwdError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('wrongPwdFormat');
    return false;
  }

  hasConfirmError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.get('confirm')!.touched && ctl.getError('mismatch');
    return false;
  }

  hasMinLengthError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('minlength');
    return false;
  }

  hasMaxLengthError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('maxlength');
    return false;
  }

  hasTooMuchKeywordsError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('tooMuchKeywords');
    return false;
  }

  hasKeywordSizeError(ctl: AbstractControl | null): boolean {
    if (!ctl) return false;
    const fg: FormGroup = ctl as FormGroup;
    const fctl: FormControl = fg.get('word') as FormControl;
    return fctl.touched && fctl.hasError('keywordSize');
  }

  hasGreaterDateError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('greaterDate');
    return false;
  }
}
