import { INTERCEPTED_ERRORS } from '../../interceptors/header.interceptor';
import { Component, Input, OnInit } from '@angular/core';
import { UserProfileData } from '@cgplib/model/Users';
import { UserEntityService } from '@cgplib/services/user-entity.service';
import { LeadsService } from '@cgplib/shared/services/leads.service';
import { ToasterService } from '@cgplib/shared/services/toaster.service';
import { MembershipLevel } from '../../model/Membership';

@Component({
  selector: 'Navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  userProfile!: UserProfileData;
  notOpenedLeads: number = 0;
  levels = MembershipLevel

  @Input() activeLink: string = ""
  @Input() activeDropdown: string = ""

  constructor(
    public user: UserEntityService,
    private toasterService: ToasterService,
    private leadsService: LeadsService
  ) {
    this.user.setUser();
  }

  ngOnInit(): void {
    this.user.fetchUserDetails().subscribe({
      next: (userData: UserProfileData) => {
        this.userProfile = userData;
      },
      error: (err: any) => { 
        if(!INTERCEPTED_ERRORS.includes(err.status)) this.toasterService.showError(err.message);
      }
    });

    this.leadsService.fetchNotOpenedLeads().subscribe({
      next: result => this.notOpenedLeads = result.total,
      error: (err: any) => { 
        if(!INTERCEPTED_ERRORS.includes(err.status)) this.toasterService.showError(err.message);
      }
    })
  }

  toggleSideBar() {
    const body: Element = document.querySelector('body') as Element;
    body.classList.toggle('toggle-sidebar');
  }

}
