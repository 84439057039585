import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  monthsShort = [
    'Jan',
    'Fev',
    'Mar',
    'Av',
    'Mai',
    'Juin',
    'Juil',
    'Aou',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  constructor() {}

  getMonthsAsCategories(): string[] {
    const months: string[] = [];
    const today: Date = new Date();
    for (let i = 0; i <= today.getMonth(); i++)
      months.push(this.monthsShort[i]);
    return months;
  }
}
