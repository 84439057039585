import { UserEntityService } from '../services/user-entity.service';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ToasterService } from '../shared/services/toaster.service';
import { Router } from '@angular/router';

export const BYPASS_HEADER = new HttpContextToken(() => false);
export const INTERCEPTED_ERRORS: number[] = [401, 403, 404];
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private user: UserEntityService,
    private toasterService: ToasterService,
    private router: Router,

  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.context.get(BYPASS_HEADER) === true) return next.handle(req);
    this.user.setUser();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.user.getToken(),
    };

    let cloneRequest = req.clone({ setHeaders: headers })
    return next.handle(cloneRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toasterService.clear();
        if(error.status === 401 && error.error) {
          this.router.navigate(['login']);
          this.toasterService.showError("Votre session a expiré. Veuillez-vous reconnecter.");
          this.user.logout();
        }
        if(error.status === 404 && error.error) {
          // this.router.navigate(['not-found']);
          this.toasterService.clear();
          this.toasterService.showError(error.error.message);
        }
        if(error.status === 403 && error.error) {
          this.toasterService.clear();
          this.toasterService.showError("Vous n'êtes pas autorisé à accéder à cette resource. Veuillez contacter le service client pour plus d'informations.");
        }
        return throwError(() => error);
      })
    )

  }
}
