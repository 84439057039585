import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LeadModalComponent } from '@cgplib/shared/lead-modal/lead-modal.component';
import { Lead } from '@cgplib/model/Lead';
import { LeadsService } from '@cgplib/shared/services/leads.service';
import { UserEntityService } from '@cgplib/services/user-entity.service';
import { PaginatorComponent } from '@cgplib/shared/paginator/paginator.component';
import { DatesService } from '@cgplib/shared/services/dates.service';
import { ToasterService } from '@cgplib/shared/services/toaster.service';
import { ResultData } from '../../model/Shared';
import { LeadsCSVGenerator } from '../services/leadCSVGenerator.service';

@Component({
  selector: 'app-leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.scss'],
})
export class LeadsListComponent implements OnInit {
  notOpenedLeads!: ResultData<Lead>;
  openedLeads!: ResultData<Lead>;

  pagesOpened: number = 0;
  searchOpened: FormControl = new FormControl('');
  @ViewChild(PaginatorComponent, { static: true })
  paginator!: PaginatorComponent;

  selectedLead!: Lead;
  @ViewChild('leadModal') leadModal: LeadModalComponent | undefined;

  constructor(
    private user: UserEntityService,
    private leadsService: LeadsService,
    private toasterService: ToasterService,
    public leadsCSVGenerator: LeadsCSVGenerator,
    public datesService: DatesService
  ) {
    this.user.setUser();
  }

  // Data functions
  ngOnInit(): void {
    this.fetchNotOpenedLeads();
    this.fetchOpenedLeads();
  }

  
  fetchNotOpenedLeads(): void {
    this.leadsService.fetchNotOpenedLeads().subscribe({
      next: (result) => (this.notOpenedLeads = result),
      error: (err: any) => this.toasterService.showError(err.message),
    });
  }

  fetchOpenedLeads(): void {
    const queryParams = this.paginator.getQueryParams();
    this.leadsService
      .fetchOpenedLeads(this.searchOpened.value, queryParams?.p, queryParams?.r)
      .subscribe({
        next: (result) => (this.openedLeads = result),
        error: (err: any) => this.toasterService.showError(err.message),
      });
  }

  updateOpenedLeads(event: any) {
    this.fetchOpenedLeads();
  }

  updateLeads(event: any) {
    this.fetchNotOpenedLeads();
    this.fetchOpenedLeads();
  }

  // Modal functions
  openLeadModal(lead: Lead) {
    this.selectedLead = lead;
    this.leadModal?.setLead(this.selectedLead);
    this.leadModal?.openLeadModal();
  }

  downloadCSVFile() {
    const content = this.leadsCSVGenerator.generateCSV(this.openedLeads.data);
    const timestamp = this.datesService.formatDateTimeForCSV();
    const blob = new Blob([content], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    this.createHTMLAnchor(url, timestamp);

    //clean up memory and prevent resource leaks.
    URL.revokeObjectURL(url);
  }

  private createHTMLAnchor(url: string, timestamp: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'OpenedLeads' + timestamp + '.csv';
    link.click();
    // return link;
  }
}
