import { UserRegistrationData } from '../../model/Users';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CompanyProfile } from '../../model/Companies';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  isRegistrationNeeded(idCompany: string) {
    return this.http.get<{registered: boolean}>(`${environment.API_URL}/companies/check-registration/${idCompany}`).pipe()
  }

  isSuccessRegistrationPossible(idCompany: string) {
    return this.http.get<{legit: boolean}>(`${environment.API_URL}/companies/blocked-users/${idCompany}`).pipe()
  }

  registerUser(idCompany: string, formValues: any) {
    const data: UserRegistrationData = {
      idCompany,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      pwd: formValues.pwd,
      mail: formValues.mail
    }
    return this.http.post(`${environment.API_URL}/companies/finish-registration`, data).pipe()
  }

  fetchCompanyInfos(idCompany: string | undefined) {
    return this.http.get<CompanyProfile>(`${environment.API_URL}/companies/${idCompany}`).pipe()
  }

  // TODO: Page activation du compte
  activateUser(id: string) {
    return this.http.put(`${environment.API_URL}/users/activate/${id}`, {})
  }
}
