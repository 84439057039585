import { UserEntityService } from '../../services/user-entity.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ValidatorsService } from '../../services/validators.service';
import { environment } from '../../../environments/environment';
import { ToasterService } from '../../shared/services/toaster.service';
import { INTERCEPTED_ERRORS } from '../../interceptors/header.interceptor';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup = this.fb.group({
    subject: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    message: new FormControl('', [
      Validators.required,
      Validators.minLength(15),
    ]),
  });

  errorsMessages = {
    required: 'Ce champ est obligatoire',
    minlengthSubject:
      'Pas assez de caractères. Minimum 5',
    minlengthMessage:
      'Pas assez de caractères. Minimum 15',
  };

  constructor(
    private fb: FormBuilder,
    public validatorsService: ValidatorsService,
    private http: HttpClient,
    private toasterService: ToasterService,
    private user: UserEntityService
  ) {
    this.user.setUser();
  }

  ngOnInit(): void {}

  sendContact(): void {
    if (this.contactForm.invalid) return;
    this.toasterService.show('Envoi en cours');
    const data = this.contactForm.value;
    this.http.post(`${environment.API_URL}/bo/contact`, data).subscribe({
      next: () => {
        this.contactForm.reset();
        this.toasterService.clear();
        this.toasterService.showSuccess('Message envoyé avec succès');
      },
      error: (err: any) => {
        if (!INTERCEPTED_ERRORS.includes(err.status))
          this.toasterService.showError(err.message);
      },
    });
  }
}
