import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserEntityService } from '../../services/user-entity.service';

@Component({
  selector: 'ConfirmDeleteModal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDeleteModalComponent implements OnInit {
  @Output() confirmChange = new EventEmitter
  @ViewChild('confirmModal') confirmModal: TemplateRef<any> | undefined;
  closeResult = '';

  constructor(
    private user: UserEntityService,
    public modalService: NgbModal,
  ) {
    this.user.setUser();
  }

  ngOnInit(): void {
  }

   // Modal functions
  openConfirmModal() {
    this.modalService.dismissAll();
    this.openModal(this.confirmModal);
  }

  openModal(content: any) {
    this.modalService
      .open(content, { size: 'md', centered: true, backdrop: 'static', scrollable: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  executeDelete() {
    this.confirmChange.emit()
  }
}
