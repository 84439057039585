<main>
  <div class="container">
    <section
      class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center"
          >
            <div class="d-flex justify-content-center py-4">
              <a
                href="index.html"
                class="logo d-flex align-items-center w-auto"
              >
                <img src="../../assets/theme/img/logo.png" alt="" />
                <span class="d-none d-lg-block">Mon Courtier & Moi</span>
              </a>
            </div>

            <div class="card mb-3">
              <div class="card-body">
                <div class="pt-4 pb-2">
                  <h5 class="card-title text-center pb-0 fs-4">
                    Vérification de votre adresse mail
                  </h5>

				  <div class="loading" *ngIf="this.loading" >
					<div class="spinner-border">
						<span class="visually-hidden">Vérification ...</span>
					</div>
					La vérification de votre mail est en cours.
				  </div>

				  <div class="success">
					<img
					id="home-img"
					class="img-fluid"
					src="/assets/img/success.gif"
					alt="success picture"
					/>
					<p>
						Votre email a été vérifié. <br>
						Vous pouvez à présent accéder à votre espace.
					</p>
					<a routerLink="/login" class="btn btn-primary">Me connecter</a>
				  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
