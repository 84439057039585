import { Company } from "./Companies";

export enum UsersRole {
  ADMIN = 'Administrateur',
  COMPANY = "Représantant d'entreprise",
  MODERATOR = 'Modérateur',
}

export interface Users {
  _id: string;
  idCompany: string;
  mail: string;
  hpwd: string;
  firstName: string;
  lastName: string;
  role: UsersRole;
  deactivated: boolean;
  lastConnection: Date;
}

export interface UserRegistrationData {
  idCompany: string;
  lastName: string;
  firstName: string;
  mail: string;
  pwd: string;
}

export interface UserProfileData {
  _id: string;
  idCompany: { _id: string; commercialName: string };
  firstName: string;
  lastName: string;
}

export interface PopulatedUser {
  _id: string;
  idCompany: Company;
  mail: string;
  hpwd: string;
  firstName: string;
  lastName: string;
  role: UsersRole;
  deactivated: boolean;
  lastConnection: Date;
}