import { ValidatorsService } from '@cgplib/services/validators.service';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MembershipsService } from '@cgplib/admin/services/memberships.service';
import { UserEntityService } from '@cgplib/services/user-entity.service';
import { ToasterService } from '@cgplib/shared/services/toaster.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MembershipLevel, MembershipPeriods } from '../../../model/Membership';

@Component({
  selector: 'AddMembershipModal',
  templateUrl: './add-membership-modal.component.html',
  styleUrls: ['./add-membership-modal.component.scss']
})
export class AddMembershipModalComponent implements OnInit {

  @ViewChild('addMembershipModal') addMembershipModal: TemplateRef<any> | undefined;
  closeResult = "";

  levels = MembershipLevel;
  periods = MembershipPeriods;

  membershipForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(5)]),
    priceBoarding: new FormControl(0, [Validators.required]),
    level: new FormControl(MembershipLevel.PREMIUM, [Validators.required]),
    period: new FormControl(MembershipPeriods.YEARLY, [Validators.required]),
  });

  errorsMessages = {
    required: 'Ce champ est obligatoire',
    minlength:
      'Pas assez de caractères. Minimum 5',
  };

  @Output() onCloseModal: EventEmitter<any> = new EventEmitter();


  constructor(
    public user: UserEntityService,
    private service: MembershipsService,
    private toasterService: ToasterService,
    public validatorsService: ValidatorsService,
    public modalService: NgbModal,
  ) {
    this.user.setUser();
  }

  ngOnInit(): void {
  }

  addMembership(){
    const data = {
      name: this.membershipForm.value.name as string,
      priceBoarding: this.membershipForm.value.priceBoarding as number,
      level: this.membershipForm.value.level as MembershipLevel,
      period: this.membershipForm.value.period as MembershipPeriods,
    }
    this.service.addMembership(data).subscribe({
      next: () => {
        this.toasterService.showSuccess("Abonnement ajouté avec succès!");
        this.membershipForm.reset();
        this.modalService.dismissAll();
        this.onCloseModal.emit();
      },
      error: (error) => {
        this.toasterService.showError(error.error.message);
      }
    })
  }

  openAddMembershipModal() {
    this.openModal(this.addMembershipModal);
  }

  openModal(content: any) {
    this.modalService
      .open(content, { size: 'md', centered: true, backdrop: 'static' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    this.membershipForm.reset()
    this.modalService.dismissAll();
  }

}
