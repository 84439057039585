<Navbar activeLink="leads"></Navbar>

<main id="main" class="main">
  <div class="pagetitle">
    <h1>Leads</h1>
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Accueil</a></li>
        <li class="breadcrumb-item active">Leads</li>
      </ol>
    </nav>
  </div>

  <section class="section">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Nouveaux leads</h5>
            <div class="table-responsive">
              <table class="table" *ngIf="notOpenedLeads">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let lead of notOpenedLeads.data">
                    <td>
                      {{ datesService.formatDateAlt(lead.dateOfCreation) }}
                    </td>
                    <td>
                      <button class="btn btn-primary" (click)="openLeadModal(lead)">Ouvrir</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Tous les leads</h5>
            <div class="search col-md-5 ms-auto">
              <form class="row g-3 justify-content-end">
                <div class="col-6" *ngIf="openedLeads && openedLeads.data">
                  <button class="btn btn-primary" (click)="downloadCSVFile()">Télécharger tous les leads lu</button>
                </div>
                <div class="col-6">
                  <input type="text" class="form-control" placeholder="Rechercher" [formControl]="searchOpened" (ngModelChange)="updateOpenedLeads($event)">
                </div>
              </form>
            </div>

            <div class="table-responsive">
              <table class="table" *ngIf="openedLeads">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Converti</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let lead of openedLeads.data">
                    <td>
                      {{ datesService.formatDateAlt(lead.dateOfCreation) }}
                    </td>
                    <td>{{ lead.lastName }}</td>
                    <td>{{ lead.firstName }}</td>
                    <td>{{lead.converted ? "Oui": "Non"}}</td>
                    <td>
                      <button class="btn btn-primary me-1" (click)="openLeadModal(lead)">Voir</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Paginator #paginator (refreshData)="fetchOpenedLeads()" [totalRows]="this.openedLeads && this.openedLeads.total ? this.openedLeads.total : 10"> </Paginator>
          </div>
        </div>
      </div>
    </div>
  </section>

  <LeadModal #leadModal [lead]="selectedLead" (onCloseModal)="updateLeads($event)"></LeadModal>
</main>
