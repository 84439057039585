<Navbar activeLink="dashboard"></Navbar>

<main id="main" class="main">
  <div class="pagetitle">
    <h1>Dashboard</h1>
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Accueil</a></li>
        <li class="breadcrumb-item active">Dashboard</li>
      </ol>
    </nav>
  </div>

  <section class="section dashboard">
    <div class="row">
      <div class="col-12">
        <div *ngIf="this.unpaid && this.unpaid.payments > 0" class="alert alert-warning alert-dismissible fade show" role="alert">
          Vous avez un ou plusieurs paiement à régler. Régularisez votre situation ou votre compte sera bloqué !
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div *ngIf="this.unpaid && this.unpaid.transactions > 0" class="alert alert-warning alert-dismissible fade show" role="alert">
          Vous avez une ou plusieurs transactions à payer. Régularisez votre situation ou votre compte sera bloqué
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="row">
          <div class="col-xxl-4 col-md-6">
            <div class="card info-card sales-card">
              <div class="filter">
                <a class="icon" href="#" data-bs-toggle="dropdown"
                  ><i class="bi bi-three-dots"></i
                ></a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li class="dropdown-header text-start">
                    <h6>Filtres</h6>
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsLeads(periodFilters.DAY)"
                      >{{ periodFilters.DAY }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsLeads(periodFilters.MONTH)"
                      >{{ periodFilters.MONTH }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsLeads(periodFilters.YEAR)"
                      >{{ periodFilters.YEAR }}</a
                    >
                  </li>
                </ul>
              </div>

              <div class="card-body">
                <h5 class="card-title">
                  Leads <span>| {{ filterLeads }}</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div
                    class="card-icon rounded-circle d-flex align-items-center justify-content-center"
                  >
                    <i class="bi bi-person-plus"></i>
                  </div>
                  <div class="ps-3" *ngIf="statsLeads">
                    <h6>{{ statsLeads.current }}</h6>
                    <span
                      [class.text-success]="
                        statsLeads.evolution === evolution.INCREASE
                      "
                      [class.text-danger]="
                        statsLeads.evolution === evolution.DECREASE
                      "
                      [class.text-primary]="
                        statsLeads.evolution === evolution.STABLE
                      "
                      class="small pt-1 fw-bold"
                      >{{ statsLeads.rate }}%</span
                    >
                    <span class="text-muted small pt-2 ps-1">{{
                      statsLeads.evolution
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-4 col-md-6">
            <div class="card info-card revenue-card">
              <div class="filter">
                <a class="icon" href="#" data-bs-toggle="dropdown"
                  ><i class="bi bi-three-dots"></i
                ></a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li class="dropdown-header text-start">
                    <h6>Filtres</h6>
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsProfile(periodFilters.DAY)"
                      >{{ periodFilters.DAY }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsProfile(periodFilters.MONTH)"
                      >{{ periodFilters.MONTH }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsProfile(periodFilters.YEAR)"
                      >{{ periodFilters.YEAR }}</a
                    >
                  </li>
                </ul>
              </div>

              <div class="card-body">
                <h5 class="card-title">
                  Profil <span>| {{ filterProfile }}</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div
                    class="card-icon rounded-circle d-flex align-items-center justify-content-center"
                  >
                    <i class="bi bi-person"></i>
                  </div>
                  <div class="ps-3" *ngIf="statsProfile">
                    <h6>{{ statsProfile.current }}</h6>
                    <span
                      [class.text-success]="
                        statsProfile.evolution === evolution.INCREASE
                      "
                      [class.text-danger]="
                        statsProfile.evolution === evolution.DECREASE
                      "
                      [class.text-primary]="
                        statsProfile.evolution === evolution.STABLE
                      "
                      class="small pt-1 fw-bold"
                      >{{ statsProfile.rate }}%</span
                    >
                    <span class="text-muted small pt-2 ps-1">{{
                      statsProfile.evolution
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-4 col-xl-12">
            <div class="card info-card customers-card">
              <div class="filter">
                <a class="icon" href="#" data-bs-toggle="dropdown"
                  ><i class="bi bi-three-dots"></i
                ></a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li class="dropdown-header text-start">
                    <h6>Filtres</h6>
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsArticles(periodFilters.DAY)"
                      >{{ periodFilters.DAY }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsArticles(periodFilters.MONTH)"
                      >{{ periodFilters.MONTH }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      (click)="updateStatsArticles(periodFilters.YEAR)"
                      >{{ periodFilters.YEAR }}</a
                    >
                  </li>
                </ul>
              </div>

              <div class="card-body">
                <h5 class="card-title">
                  Vues d'articles <span>| {{ filterArticles }}</span>
                </h5>

                <div class="d-flex align-items-center">
                  <div
                    class="card-icon rounded-circle d-flex align-items-center justify-content-center"
                  >
                    <i class="bi bi-newspaper"></i>
                  </div>
                  <div class="ps-3" *ngIf="statsArticles">
                    <h6>{{ statsArticles.current }}</h6>
                    <span
                      [class.text-success]="
                        statsArticles.evolution === evolution.INCREASE
                      "
                      [class.text-danger]="
                        statsArticles.evolution === evolution.DECREASE
                      "
                      [class.text-primary]="
                        statsArticles.evolution === evolution.STABLE
                      "
                      class="small pt-1 fw-bold"
                      >{{ statsArticles.rate }}%</span
                    >
                    <span class="text-muted small pt-2 ps-1">{{
                      statsArticles.evolution
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card">
              <div class="filter">
                <a class="icon" href="#" data-bs-toggle="dropdown"
                  ><i class="bi bi-three-dots"></i
                ></a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li class="dropdown-header text-start">
                    <h6>Filter</h6>
                  </li>

                  <li><a class="dropdown-item" href="#">Today</a></li>
                  <li><a class="dropdown-item" href="#">This Month</a></li>
                  <li><a class="dropdown-item" href="#">This Year</a></li>
                </ul>
              </div>

              <div class="card-body">
                <h5 class="card-title">Reports <span>/Today</span></h5>
                <div id="chart" *ngIf="chartOptions">
                  <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [xaxis]="chartOptions.xaxis"
                    [dataLabels]="chartOptions.dataLabels"
                    [grid]="chartOptions.grid"
                    [stroke]="chartOptions.stroke"
                    [title]="chartOptions.title"
                  ></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
