import { Component, OnInit, ViewChild } from '@angular/core';
import { PeriodFilter, Evolution, StatsData } from '../../cgp/services/dashboard.service';
import { ChartComponent, ApexAxisChartSeries } from 'ng-apexcharts';
import { ChartOptions } from '../../cgp/dashboard/dashboard.component';
import { DashboardService } from '../services/dashboard.service';
import { UserEntityService } from '../../services/user-entity.service';
import { ChartsService } from '../../shared/services/charts.service';
import { ToasterService } from '../../shared/services/toaster.service';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  periodFilters = PeriodFilter;
  evolution = Evolution;

  filterLeads: PeriodFilter = PeriodFilter.MONTH;
  statsLeads!: StatsData;

  filterProfile: PeriodFilter = PeriodFilter.MONTH;
  statsProfile!: StatsData;

  filterArticles: PeriodFilter = PeriodFilter.MONTH;
  statsArticles!: StatsData;

  filterRegistration: PeriodFilter = PeriodFilter.MONTH;
  statsRegistration!: StatsData;

  unpaid!: {transactions: number, payments: number};

  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions!: ChartOptions;
  chartSeries!: ApexAxisChartSeries;

  constructor(
    private dashboardService: DashboardService,
    private toasterService: ToasterService,
    private user: UserEntityService,
    public chartsService: ChartsService
  ) {
    this.user.setUser();
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: "Statistiques sur l'année en cours",
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: this.chartsService.getMonthsAsCategories(),
      },
    };
  }
  ngOnInit(): void {
    this.fetchStatsLeads();
    this.fetchStatsProfile();
    this.fetchStatsArticles();
    this.fetchStatsRegistration();
    this.fetchChartData();
  }

  fetchStatsLeads() {
    this.dashboardService.fetchStatsLeads(this.filterLeads).subscribe({
      next: (stats: StatsData) => {
        this.statsLeads = stats;
        this.statsLeads.rate = this.dashboardService.getRate(stats);
        this.statsLeads.evolution = this.dashboardService.getEvolution(stats);
      },
      error: (err: any) => {
        this.toasterService.showError(err.message);
      },
    });
  }

  fetchStatsProfile() {
    this.dashboardService.fetchStatsProfile(this.filterProfile).subscribe({
      next: (stats: StatsData) => {
        this.statsProfile = stats;
        this.statsProfile.rate = this.dashboardService.getRate(stats);
        this.statsProfile.evolution = this.dashboardService.getEvolution(stats);
      },
      error: (err: any) => {
        this.toasterService.showError(err.message);
      },
    });
  }

  fetchStatsArticles() {
    this.dashboardService.fetchStatsArticles(this.filterArticles).subscribe({
      next: (stats: StatsData) => {
        this.statsArticles = stats;
        this.statsArticles.rate = this.dashboardService.getRate(stats);
        this.statsArticles.evolution =
          this.dashboardService.getEvolution(stats);
      },
      error: (err: any) => {
        this.toasterService.showError(err.message);
      },
    });
  }

  fetchStatsRegistration() {
    this.dashboardService.fetchStatsRegistration(this.filterRegistration).subscribe({
      next: (stats: StatsData) => {
        this.statsRegistration = stats;
        this.statsRegistration.rate = this.dashboardService.getRate(stats);
        this.statsRegistration.evolution = 
          this.dashboardService.getEvolution(stats);
      },
      error: (err: any) => {
        this.toasterService.showError(err.message);
      },
    }) 
  }

  fetchChartData() {
    this.dashboardService.fetchChartData().subscribe({
      next: (data: { series: ApexAxisChartSeries }) => {
        this.chartOptions.series = data.series;
      },
      error: (err: any) => {
        this.toasterService.showError(err.message);
      },
    });
  }

  updateStatsLeads(filter: PeriodFilter) {
    this.filterLeads = filter;
    this.fetchStatsLeads();
  }

  updateStatsArticles(filter: PeriodFilter) {
    this.filterArticles = filter;
    this.fetchStatsArticles();
  }

  updateStatsProfile(filter: PeriodFilter) {
    this.filterProfile = filter;
    this.fetchStatsProfile();
  }

  updateStatsRegistration(filter: PeriodFilter) {
    this.filterRegistration = filter;
    this.fetchStatsRegistration()
  }
}
