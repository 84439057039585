import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '@cgplib/admin/dashboard/dashboard.component';
import { AdminOrModeratorGuardService } from '@cgplib/services/admin-or-moderator-guard.service';
import { AuthGuardService } from '@cgplib/services/auth-guard.service';
import { ProfileComponent } from './profile/profile.component';
import { MembershipsComponent } from './memberships/memberships.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService, AdminOrModeratorGuardService],
    title: 'Dashboard | Espace Administration | MC&M'
  },
  {
    path: 'leads',
    loadChildren: () =>
      import('./leads/leads.module').then((m) => m.LeadsModule),
    canActivate: [AuthGuardService, AdminOrModeratorGuardService],
  },
  {
    path: 'articles',
    loadChildren: () => 
      import('./articles/articles.module').then((m) => m.ArticlesModule),
    canActivate: [AuthGuardService, AdminOrModeratorGuardService],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./company/company.module').then((m) => m.CompanyModule),
    canActivate: [AuthGuardService, AdminOrModeratorGuardService],
  },
  {
    path: 'profile', 
    component: ProfileComponent,
    canActivate: [AuthGuardService, AdminOrModeratorGuardService],
    title: 'Profil | Espace Administration | MC&M'
  },
  {
    path: 'memberships',
    component: MembershipsComponent,
    canActivate: [AuthGuardService, AdminOrModeratorGuardService],
    title: 'Abonnements | Espace Administration | MC&M'
  },
  {
    path: 'contact',
    loadChildren: () => 
      import('./contact/contact.module').then((m) => m.ContactModule),
    canActivate: [AuthGuardService, AdminOrModeratorGuardService],
  },
  {
    path:'simulators', 
    loadChildren: () => 
      import('./simulators/simulator.module').then(m => m.SimulatorsModule), 
      canActivate: [AuthGuardService, AdminOrModeratorGuardService],
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
