import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Lead, PopulatedLead } from '@cgplib/model/Lead';
import { UserEntityService } from '@cgplib/services/user-entity.service';
import { DatesService } from '@cgplib/shared/services/dates.service';
import { LeadsService } from '@cgplib/shared/services/leads.service';
import { ToasterService } from '@cgplib/shared/services/toaster.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsService } from '../../services/validators.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'LeadModal',
  templateUrl: './lead-modal.component.html',
  styleUrls: ['./lead-modal.component.scss'],
})
export class LeadModalComponent implements OnInit {

  //error on the opening of the modal
  markLeadOpenError: boolean = false;
  //boolean to check if we chose the lead modal
  openLead: boolean = true;
  //boolean to check if we chose the error modal 
  openError: boolean = false;
  //message for closing reason
  closeResult = "";
  //error message for the form submission when reporting a corrupting lead
  errorsMessages= {
    required: "Ce champ est obligatoire",
    notEnoughText: "Veuillez décrire davantage le problème rencontré",
  }

  @Input() lead!: Lead | PopulatedLead;

  @ViewChild('leadModal') leadModal: TemplateRef<any> | undefined;
  @ViewChild('corruptionModal') corruptionModal: TemplateRef<any> | undefined;
  @ViewChild('confirmModal') confirmModal: TemplateRef<any> | undefined;

  @Output() onCloseModal: EventEmitter<any> = new EventEmitter();

  reportFC = new FormControl("", [Validators.required, Validators.minLength(10)]);


  constructor(
    public user: UserEntityService,
    private leadsService: LeadsService,
    private toasterService: ToasterService,
    public modalService: NgbModal,
    public datesService: DatesService,
    public validatorService: ValidatorsService,

  ) {
    this.user.setUser();
  }

  ngOnInit(): void {
    if (!this.lead?.opened) this.markLeadAsOpen();
  }

  setLead(lead: Lead | PopulatedLead) {
    this.lead = lead;
    if (!this.lead?.opened && this.user.getIdCompany() === this.lead.idCompany) this.markLeadAsOpen();
    else {
      this.markLeadOpenError = false
    }

  }

  markLeadAsOpen() {
    if(!this.lead) return;
    this.leadsService.markLeadAsOpen(this.lead._id).subscribe({
      next: () => {
        this.lead.opened = true;
        this.markLeadOpenError = false;
      },
      error: (err: any) => {
        this.toasterService.showError(err.message);
        this.markLeadOpenError = true;
      },
    });
  }

  markLeadAsConverted() {
    if(!this.lead) return;
    this.leadsService.markLeadAsConverted(this.lead._id).subscribe({
      next: () => {
        this.lead.converted = true;
        this.toasterService.showSuccess("Modification enregistrée avec succès");
        this.lead.dateOfConversion = new Date();
      },
      error: (err: any) => this.toasterService.showError(err.message)
    });
  }

  markLeadAsCorrupted(): void{
    if (!this.lead || this.reportFC.invalid) return;
    this.saveCorruptedForm();
    this.closeModal();
  }  


  // form function

  saveCorruptedForm() {
    this.leadsService.markLeadAsCorrupted(this.lead._id,this.reportFC.value as string).subscribe({
      next: () => this.toasterService.showSuccess("Votre signalement a été pris en compte."),
      error: (err: any) => {
        this.toasterService.showError(err.message);
        this.markLeadOpenError = true;
      },
    })
  }

  // Modal functions 
  openLeadModal() {
    this.modalService.dismissAll();
    this.openModal(this.leadModal);
  }

  openErrorModal() {
    this.modalService.dismissAll();
    this.openModal(this.corruptionModal);
  }

  openConfirmModal() {
    this.modalService.dismissAll();
    this.openModal(this.confirmModal);
  }


  openModal(content: any) {
    this.modalService
      .open(content, { size: 'lg', centered: true, backdrop: 'static' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.onCloseModal.emit();
  }

  undoCorrupted(){
    this.closeModal();
    this.openLead = true;
    this.openError = false;
    this.openLeadModal();
  }

}
