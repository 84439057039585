import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddMembershipModalComponent } from './memberships/add-membership-modal/add-membership-modal.component';
import { MembershipsComponent } from './memberships/memberships.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProfileComponent } from './profile/profile.component';
import { AdminRoutingModule } from './admin-routing.module';


@NgModule({
  declarations: [
    NavbarComponent,
    DashboardComponent,
    ProfileComponent,
    MembershipsComponent,
    AddMembershipModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgApexchartsModule,
    EditorModule,
    AdminRoutingModule,
    SharedModule
  ],
  exports: [
    NavbarComponent
  ],
  providers: [Title]
})
export class AdminModule { }
