import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from '../shared/services/toaster.service';
import { LoginService } from '../services/login.service';
import { UserEntityService } from '../services/user-entity.service';
import { ForgotPwdComponent } from './../forgot-pwd/forgot-pwd.component';
import { ValidatorsService } from './../services/validators.service';
import { INTERCEPTED_ERRORS } from '../interceptors/header.interceptor';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild("forgotPwdModals") forgotPwdModals!: ForgotPwdComponent;

  loginForm: UntypedFormGroup = new UntypedFormGroup({
    login: new UntypedFormControl("yaniv.guenoun@moncourtieretmoi.com",  [Validators.required, Validators.email]),
    pwd: new UntypedFormControl("Userp@ss2812", [Validators.required]),
    rememberMe: new UntypedFormControl(false)
  })

  showPwdText: boolean = false;

  constructor(
    private toasterService: ToasterService,
    private router: Router,
    private loginService: LoginService,
    public validatorsService: ValidatorsService,
    private user: UserEntityService
  ) { }

  ngOnInit(): void {
  }

  login() {
    if(this.loginForm.invalid) return;
    this.loginService.auth(this.loginForm.value).subscribe({
      next: (response: any) => {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(response))
        this.user.setUser()
        if(this.user.isLogedIn() && this.user.isUserCompany()) {
          this.router.navigate(['cgp', 'dashboard'])
        }
        if(this.user.isLogedIn() && (this.user.isUserAdmin() || this.user.isUserModerator())) {
          this.router.navigate(['admin', 'dashboard'])
        }

      },
      error: (err: any) => {
        if (!INTERCEPTED_ERRORS.includes(err.status))
          this.toasterService.showError(err.message);
      },
    })
  }

  showResetPwdModal() {
    this.forgotPwdModals.openMailModal()
  }

  showPwd() {
    this.showPwdText = !this.showPwdText;
  }

}
