import { UserProfileData, UsersRole } from './../model/Users';
import { environment } from './../../environments/environment';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { BYPASS_HEADER } from '../interceptors/header.interceptor';
import { ToasterService } from '../shared/services/toaster.service';
import { MembershipLevel } from '../model/Membership';

@Injectable({
  providedIn: 'root',
})
export class UserEntityService {
  private token?: string;
  private id?: string;
  private role?: string;
  private idCompany?: string;
  private logedIn: boolean;
  private level!: MembershipLevel;

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private router: Router,
    @Optional() @SkipSelf() userEntityService: UserEntityService
  ) {
    this.logedIn = false;
  }

  public setUser() {
    if (this.logedIn) {
      this.logedIn = this.isLogedIn();
    }
    if (!this.logedIn) {
      try {
        const data = JSON.parse(localStorage.getItem('user') || '{}');
        this.setData(data);
        this.fetchUserDetails();
        if(this.role === UsersRole.COMPANY) this.setLevel();
      } catch (e) {
        console.error(e);
        this.logout()
      }
    }
  }

  public setData({ token, id, role, idCompany }: any) {
    this.token = token;
    this.id = id;
    this.role = role;
    this.idCompany = idCompany;
    this.logedIn =
      this.token !== undefined &&
      this.id !== undefined &&
      this.role !== undefined;
      // this.idCompany !== undefined;
  }

  private setLevel() {
    this.fetchLevelOfMembership().subscribe({
      next: res => {
        this.level = res.level
      }
    })
  }

  public toString(): string {
    return `User: { token: ${this.token}, \n id: ${this.id},\n role: ${this.role},\nidCompany: ${this.idCompany} }`;
  }

  public getToken(): string | undefined {
    return this.token;
  }

  public getId(): string | undefined {
    return this.id;
  }

  public getKind(): string | undefined {
    return this.role;
  }

  public getIdCompany(): string | undefined {
    return this.idCompany;
  }

  public getLevel(): string | undefined {
    return this.level;
  }

  public isUserAdmin(): boolean {
    if (!this.role) return false;
    return this.role === UsersRole.ADMIN;
  }

  public isUserModerator(): boolean {
    if (!this.role) return false;
    return this.role === UsersRole.MODERATOR;
  }

  public isUserCompany(): boolean {
    if (!this.role) return false;
    return this.role === UsersRole.COMPANY;
  }

  public isLogedIn(): boolean {
    if (!this.token) return false;
    this.http
      .get(`${environment.API_URL}/users/check-logedin`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token,
        },
        context: new HttpContext().set(BYPASS_HEADER, true)
      })
      .subscribe({
        next: () => {
          this.logedIn = true;
        },
        error: (err: any) => {
          this.toasterService.clear()
          if(err.status === 401)
            this.toasterService.showError("Votre session a expiré. Veuillez-vous reconnecter.");
          else
          this.toasterService.showError(err.error.message)
        }
      });
    return this.logedIn;
  }

  public logout() {
    localStorage.removeItem('user');
    this.token = undefined;
    this.id = undefined;
    this.role = undefined;
    this.idCompany = undefined;
    this.logedIn = false;
    this.router.navigate(['/login']);
  }

  fetchUserDetails() {
    return this.http.get<UserProfileData>(`${environment.API_URL}/users/about-me`);
  }

  fetchLevelOfMembership() {
    return this.http.get<{level: MembershipLevel}>(`${environment.API_URL}/users/membership/level`)
  }
}
