<ngx-intl-tel-input
  [cssClass]="validatorsService.hasWrongNumberFormatError(this.tel)? 'form-control is-invalid' : 'form-control'"
  [onlyCountries]="onlyCountries"
  [enableAutoCountrySelect]="true"
  [enablePlaceholder]="true"
  [searchCountryFlag]="true"
  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
  [selectFirstCountry]="false"
  [selectedCountryISO]="CountryISO.France"
  [phoneValidation]="true"
  [separateDialCode]="separateDialCode"
  [numberFormat]="PhoneNumberFormat.National"
  name="tel"
  [formControl]="tel"
>
</ngx-intl-tel-input>
<div class="text-danger" *ngIf="validatorsService.hasWrongNumberFormatError(this.tel)">
  Le format est incorrect.
</div>
