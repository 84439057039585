import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEntityService } from '../../services/user-entity.service';
import { environment } from '../../../environments/environment';
import {
  Evolution,
  PeriodFilter,
  StatsData,
} from '../../cgp/services/dashboard.service';
import { ApexAxisChartSeries } from 'ng-apexcharts';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient, private user: UserEntityService) {
    this.user.setUser();
  }

  fetchStatsLeads(filter: PeriodFilter) {
    return this.http.get<StatsData>(
      `${environment.API_URL}/dashboard/admin/stats-lead?filter=${filter}`
    );
  }

  fetchStatsProfile(filter: PeriodFilter) {
    return this.http.get<StatsData>(
      `${environment.API_URL}/dashboard/admin/stats-profile?filter=${filter}`
    );
  }

  fetchStatsArticles(filter: PeriodFilter) {
    return this.http.get<StatsData>(
      `${environment.API_URL}/dashboard/admin/stats-articles?filter=${filter}`
    );
  }

  fetchStatsRegistration(filter: PeriodFilter) {
    return this.http.get<StatsData>(
      `${environment.API_URL}/dashboard/admin/stats-registration?filter=${filter}`
    );
  }

  fetchChartData() {
    return this.http.get<{ series: ApexAxisChartSeries }>(
      `${environment.API_URL}/dashboard/admin/chart-data`
    );
  }

  getEvolution(stats: StatsData): Evolution {
    if (stats.current === stats.previous) return Evolution.STABLE;
    if (stats.current > stats.previous) return Evolution.INCREASE;
    return Evolution.DECREASE;
  }

  getRate(stats: StatsData): number {
    if (stats.previous === 0) return stats.current * 100;
    return Math.abs(((stats.current - stats.previous) / stats.previous) * 100);
  }
}
