import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { INTERCEPTED_ERRORS } from '../../interceptors/header.interceptor';
import { UserEntityService } from '../../services/user-entity.service';
import { ValidatorsService } from '../../services/validators.service';
import { ToasterService } from '../../shared/services/toaster.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  infos!: { firstName: string; lastName: String; mail: String };
  descriptionFC: FormControl = new FormControl('');

  profileForm: FormGroup = this.fb.group({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    mail: new FormControl('', [Validators.email]),
  });

  passwordForm: FormGroup = new FormGroup(
    {
      oldPwd: new FormControl('', [Validators.required]),
      newPwd: new FormControl('', [
        Validators.required,
        this.validatorsService.pwdFormatValidator,
      ]),
      confirm: new FormControl('', [Validators.required]),
    },
    [this.validatorsService.matchValidator('newPwd', 'confirm')]
  );

  pwdMatch = {
    maj: false,
    num: false,
    special: false,
    length: false,
  };

  showOldPwdText: boolean = false;
  showNewPwdText: boolean = false;
  showConfirmText: boolean = false;

  constructor(
    public user: UserEntityService,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private toasterService: ToasterService,
    public validatorsService: ValidatorsService
  ) {
    this.user.setUser();
  }

  ngOnInit(): void {
    this.fetchProfileInfos();
  }

  fetchProfileInfos() {
    this.profileService.fetchProfileInfo().subscribe({
      next: (res: { firstName: string; lastName: String; mail: String }) => {
        this.infos = res;
      },
      error: (err: any) => {
        if (!INTERCEPTED_ERRORS.includes(err.status))
          this.toasterService.showError(err.message);
      },
    });
  }


  // FORMS

  // Update Profile
  updateProfile() {
    if (this.isProfileFormEmpty()) return;
    if (this.profileForm.invalid) return;
    this.profileService.updateProfile(this.profileForm.value).subscribe({
      next: () => {
        this.toasterService.showSuccess('Profil mis à jour avec succès');
        this.fetchProfileInfos();
        this.resetProfileForm();
      },
      error: (err: any) => {
        if (!INTERCEPTED_ERRORS.includes(err.status))
          this.toasterService.showError(err.message);
      },
    });
  }

  isProfileFormEmpty() {
    const values = this.profileForm.value;
    return (
      values.firstName === '' && values.lastName === '' && values.mail === ''
    );
  }

  resetProfileForm() {
    this.profileForm.get('firstName')?.setValue('');
    this.profileForm.get('lastName')?.setValue('');
    this.profileForm.get('mail')?.setValue('');
  }

  // Password change
  showOldPwd() {
    this.showOldPwdText = !this.showOldPwdText;
  }

  showNewPwd() {
    this.showNewPwdText = !this.showNewPwdText;
  }

  showConfirm() {
    this.showConfirmText = !this.showConfirmText;
  }

  updatePwdErrors(event: any) {
    const pwd: string = this.passwordForm.get('newPwd')?.value as string;
    this.pwdMatch = {
      maj: pwd.match(this.validatorsService.PWD_MAJ_REGEX) !== null,
      num: pwd.match(this.validatorsService.PWD_NUM_REGEX) !== null,
      special: pwd.match(this.validatorsService.PWD_SPECIAL_REGEX) !== null,
      length: pwd.match(this.validatorsService.PWD_LENGTH_REGEX) !== null,
    };
  }

  updatePassword() {
    console.log(this.passwordForm);
    if (this.passwordForm.invalid) return;
    this.profileService.updatePwd(this.passwordForm.value).subscribe({
      next: () => {
        this.toasterService.showSuccess('Mot de passe mis à jour avec succès');
        this.passwordForm.reset();
        this.pwdMatch = {
          maj: false,
          num: false,
          special: false,
          length: false,
        }
      },
      error: (err: any) => {
        console.log(err);
        if (!INTERCEPTED_ERRORS.includes(err.status))
          this.toasterService.showError(err);
      },
    });
  }
}
