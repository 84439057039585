import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEntityService } from '@cgplib/services/user-entity.service';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { environment } from '../../../environments/environment';

export enum PeriodFilter {
  DAY = "Aujourd'hui",
  MONTH = 'Ce mois-ci',
  YEAR = 'Cette année',
}

export enum Evolution {
  INCREASE = 'En hausse',
  DECREASE = 'En baisse',
  STABLE = 'Stable',
}

export interface StatsData {
  current: number;
  previous: number;
  rate?: number;
  evolution?: Evolution;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient, private user: UserEntityService) {
    this.user.setUser();
  }

  fetchStatsLeads(filter: PeriodFilter) {
    return this.http.post<StatsData>(
      `${environment.API_URL}/dashboard/stats-lead`,
      {
        filter,
      }
    );
  }

  fetchStatsProfile(filter: PeriodFilter) {
    return this.http.post<StatsData>(
      `${environment.API_URL}/dashboard/stats-profile`,
      {
        filter,
      }
    );
  }

  fetchStatsArticles(filter: PeriodFilter) {
    return this.http.post<StatsData>(
      `${environment.API_URL}/dashboard/stats-articles`,
      {
        filter,
      }
    );
  }

  fetchChartData() {
    return this.http.get<{ series: ApexAxisChartSeries }>(
      `${environment.API_URL}/dashboard/chart-data`
    );
  }

  getEvolution(stats: StatsData): Evolution {
    if (stats.current === stats.previous) return Evolution.STABLE;
    if (stats.current > stats.previous) return Evolution.INCREASE;
    return Evolution.DECREASE;
  }

  getRate(stats: StatsData): number {
    if (stats.previous === 0) return stats.current * 100;
    return Math.abs(((stats.current - stats.previous) / stats.previous) * 100);
  }

  fetchUnpaid() {
    return this.http.get<{ transactions: number; payments: number }>(
      ` ${environment.API_URL}/dashboard/unpaid`
    );
  }
}
