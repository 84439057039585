<main>
  <div class="container">
    <section
      class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center"
          >
            <div class="d-flex justify-content-center py-4">
              <a href="#" class="logo d-flex align-items-center w-auto">
                <img src="/assets/theme/img/logo.png" alt="Logo image" />
                <span class="d-none d-lg-block">Mon Courtier & Moi</span>
              </a>
            </div>
            <!-- End Logo -->

            <div class="card mb-3">
              <div class="card-body">
                <div class="pt-4 pb-2">
                  <h5 class="card-title text-center pb-0 fs-4">
                    Accéder à votre espace
                  </h5>
                  <p class="text-center small">
                    Saisissez vos identifiants pour vous connecter
                  </p>
                </div>

                <form class="row g-3" [formGroup]="loginForm">
                  <div class="col-12">
                    <label for="login" class="form-label">Adresse mail</label>
                    <input
                      type="text"
                      formControlName="login"
                      class="form-control"
                      [class.is-invalid]="
                        validatorsService.hasRequiredError(
                          this.loginForm.get('login')
                        ) ||
                        validatorsService.hasMailError(
                          this.loginForm.get('login')
                        )
                      "
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        validatorsService.hasRequiredError(
                          this.loginForm.get('login')
                        )
                      "
                    >
                      Ce champ est obligatoire.
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        validatorsService.hasMailError(
                          this.loginForm.get('login')
                        )
                      "
                    >
                      Le format est incorrect.
                    </div>
                  </div>

                  <div class="col-12">
                    <label for="pwd" class="form-label">Mot de passe</label>
                    <div class="input-group">
                      <input
                        [type]="showPwdText ? 'text' : 'password'"
                        class="form-control"
                        formControlName="pwd"
                        [class.is-invalid]="
                          validatorsService.hasRequiredError(
                            this.loginForm.get('pwd')
                          )
                        "
                      />
                      <button
                        class="btn btn-link-secondary"
                        type="button"
                        (click)="showPwd()"
                      >
                        <i
                          class="bi"
                          [class.bi-eye-slash]="showPwdText"
                          [class.bi-eye]="!showPwdText"
                        ></i>
                      </button>
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          validatorsService.hasRequiredError(
                            this.loginForm.get('pwd')
                          )
                        "
                      >
                        Ce champ est obligatoire.
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        formControlName="rememberMe"
                      />
                      <label class="form-check-label" for="rememberMe"
                        >Se souvenir de moi</label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      class="btn btn-primary w-100"
                      type="submit"
                      [disabled]="loginForm.invalid"
                      (click)="login()"
                    >
                      Me connecter
                    </button>
                  </div>
                  <div class="col-12">
                    <p class="small mb-0">
                      <a class="link-primary pointer" (click)="showResetPwdModal()"
                        >Mot de passe oublié ?</a
                      >
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>

<ForgotPwdModals #forgotPwdModals></ForgotPwdModals>
<!-- End #main -->
