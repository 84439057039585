import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@cgplib/services/auth-guard.service';
import { CompanyGuardService } from '../services/company-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService, CompanyGuardService],
    title: 'Dashboard | Espace Pro | MC&M',
  },
  {
    path: 'leads/list',
    component: LeadsListComponent,
    canActivate: [AuthGuardService, CompanyGuardService],
    title: 'Liste des leads | Espace Pro | MC&M',
  },
  {
    path: 'articles',
    loadChildren: () =>
      import('./articles/articles.module').then((m) => m.ArticlesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'simulators',
    loadChildren: () => 
      import('./simulators/simulators.module').then(m => m.SimulatorsModule)
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthGuardService, CompanyGuardService],
    title: 'Contact | Espace Pro | MC&M',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CgpRoutingModule {}
