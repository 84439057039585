import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '../shared/services/toaster.service';
import { LoginService } from '../services/login.service';
import { ValidatorsService } from '../services/validators.service';
import { INTERCEPTED_ERRORS } from '../interceptors/header.interceptor';

@Component({
  selector: 'ForgotPwdModals',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.scss']
})
export class ForgotPwdComponent implements OnInit {

  @ViewChild('email') emailModal: TemplateRef<any> | undefined;
  @ViewChild('waiting') waitingModal: TemplateRef<any> | undefined;
  @ViewChild('success') successModal: TemplateRef<any> | undefined;

  emailInput: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.email]);

  constructor(
    private loginService: LoginService,
    private toastsService: ToasterService,
    public modalService: NgbModal,
    public validatorsService: ValidatorsService
  ) { }

  ngOnInit(): void {
  }

  public openMailModal() {
    this.emailInput.patchValue(null);
    this.modalService.dismissAll();
    this.modalService.open(this.emailModal, {centered: true, backdrop: 'static' });
  }

  moveToSuccessModal() {
    if(this.emailInput.invalid) return;
    this.modalService.dismissAll()
    this.modalService.open(this.waitingModal, {centered: true, backdrop: 'static' });
    this.loginService.sendResetPwdEmail(this.emailInput.value)
      .subscribe({
        next: () => {
          this.modalService.dismissAll()
          this.modalService.open(this.successModal, {centered: true, backdrop: 'static' })
        },
        error: (err) => {
          this.modalService.dismissAll();
          this.modalService.open(this.emailModal, {centered: true, backdrop: 'static' });
          if (!INTERCEPTED_ERRORS.includes(err.status)) {
            this.toastsService.showError(err.message);
          }
        }, 
      })
  }

}
