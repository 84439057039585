import { UserEntityService } from './../services/user-entity.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  template: '',
  styles: []
})
export class LogoutComponent {

  constructor(private user: UserEntityService, private router: Router) {
    this.user.setUser()
    this.user.logout()
    this.router.navigate(['login'])
   }
}
