<Navbar activeLink="contact"></Navbar>

<main id="main" class="main">
  <div class="pagetitle">
    <h1>Contact</h1>
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Accueil</a></li>
        <li class="breadcrumb-item active">Contact</li>
      </ol>
    </nav>
  </div>
  <!-- End Page Title -->

  <section class="section contact">
    <div class="row gy-4">
      <div class="col-xl-6">
        <div class="card p-4 ms-auto">
          <form [formGroup]="contactForm" class="php-email-form">
            <div class="row gy-4">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Objet</label>
                  <input
                    type="text"
                    class="form-control"
                    [class.is-invalid]="
                      validatorsService.hasRequiredError(
                        this.contactForm.get('subject')
                      ) ||
                      validatorsService.hasMinLengthError(
                        this.contactForm.get('subject')
                      )
                    "
                    formControlName="subject"
                    required
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      validatorsService.hasRequiredError(
                        this.contactForm.get('subject')
                      )
                    "
                  >
                    {{ errorsMessages["required"] }}
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      validatorsService.hasMinLengthError(
                        this.contactForm.get('subject')
                      )
                    "
                  >
                    {{ errorsMessages["minlengthSubject"] }}
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <label>Message</label>
                <textarea
                  class="form-control"
                  [class.is-invalid]="
                    validatorsService.hasRequiredError(
                      this.contactForm.get('message')
                    ) ||
                    validatorsService.hasMinLengthError(
                      this.contactForm.get('message')
                    )
                  "
                  formControlName="message"
                  rows="6"
                  required
                ></textarea>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorsService.hasRequiredError(
                      this.contactForm.get('message')
                    )
                  "
                >
                  {{ errorsMessages["required"] }}
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorsService.hasMinLengthError(
                      this.contactForm.get('message')
                    )
                  "
                >
                  {{ errorsMessages["minlengthMessage"] }}
                </div>
              </div>

              <div class="col-md-12 text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="sendContact()"
                >
                  Envoyer
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
<!-- End #main -->
