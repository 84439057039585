import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationComponent } from './registration.component';
import { SuccessRegistrationComponent } from './success-registration/success-registration.component';
import { ValidationMailComponent } from './validation-mail/validation-mail.component';

const routes: Routes = [
  { path: ':id', component: RegistrationComponent },
  { path: ':id/success', component: SuccessRegistrationComponent },
  {
    path: 'validate-mail/:id',
    component: ValidationMailComponent,
    title: 'Vérification de votre adresse mail | Espace Pro | MC&M',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistrationRoutingModule {}
