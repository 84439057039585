<ng-template #loadingModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-center">Chargement en cours</h4>
	</div>
	<div class="modal-body">
		<ng-content select="[message]"></ng-content>
		<div class="spinner-border" role="status">
			<span class="visually-hidden">Chargement...</span>
		</div>
	</div>
</ng-template>