import { Component, Input, OnInit } from '@angular/core';
import { INTERCEPTED_ERRORS } from '../../interceptors/header.interceptor';
import { UserProfileData } from '../../model/Users';
import { UserEntityService } from '../../services/user-entity.service';
import { LeadsService } from '../../shared/services/leads.service';
import { ToasterService } from '../../shared/services/toaster.service';

@Component({
  selector: 'Navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  userProfile!: UserProfileData;
  notOpenedLeads: number = 0;

  @Input() activeLink: string = ""
  @Input() activeDropdown: string = ""

  constructor(
    private user: UserEntityService,
    private toasterService: ToasterService,
    private leadsService: LeadsService
  ) {
    this.user.setUser();
  }

  ngOnInit(): void {
    this.user.fetchUserDetails().subscribe({
      next: (userData: UserProfileData) => {
        this.userProfile = userData;
      },
      error: (err: any) => { 
        if(!INTERCEPTED_ERRORS.includes(err.status)) this.toasterService.showError(err.message);
      }
    });
  }

  toggleSideBar() {
    const body: Element = document.querySelector('body') as Element;
    body.classList.toggle('toggle-sidebar');
  }
}
