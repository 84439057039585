<main id="success">
  <div class="success-card">
    <div class="success-icon">
      <img
        id="home-img"
        class="img-fluid"
        src="/assets/img/success.gif"
        alt="success picture"
      />
    </div>
    <div class="success-text">
      <h2>Merci de votre inscription</h2>
      <p>
        Il ne vous reste plus qu'à vérifier votre adresse mail pour pouvoir
        accepter à votre espace.
      </p>
    </div>
  </div>
</main>
