import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginatorComponent } from '@cgplib/shared/paginator/paginator.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderInterceptor } from '@cgplib/interceptors/header.interceptor';
import { SharedRoutingModule } from '@cgplib/shared/shared-routing.module';
import { ToasterComponent } from '@cgplib/shared/toaster/toaster.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteModalComponent } from './confirm-delete/confirm-delete-modal.component';
import { LoadingPopinComponent } from './loading-popin/loading-popin.component';
import { LeadModalComponent } from './lead-modal/lead-modal.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TelInputComponent } from './tel-input/tel-input.component';


@NgModule({
  declarations: [
    ToasterComponent,
    ConfirmDeleteModalComponent,
    PaginatorComponent,
    LoadingPopinComponent,
    LeadModalComponent,
    TelInputComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgbModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxIntlTelInputModule
  ],
  exports: [
    ToasterComponent,
    ConfirmDeleteModalComponent,
    PaginatorComponent,
    LoadingPopinComponent,
    LeadModalComponent,
    TelInputComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }

  ],

})
export class SharedModule { }
