import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CguComponent } from './cgu/cgu.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component';
import { AdminGuardService } from './services/admin-guard.service';
import { AuthGuardService } from './services/auth-guard.service';
import { CompanyGuardService } from './services/company-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/logout', pathMatch: 'full' },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'login', component: LoginComponent, title: 'Connexion | Espace Pro | MC&M' },
  {
    path: 'cgu',
    component: CguComponent,
    title: 'Conditions générales d\'utilisation | Espace Pro | MC&M',
  },
  {
    path: 'reset-password/:token',
    component: ResetPwdComponent,
    title: 'Réinitialiser le mot de passe | Espace Pro | MC&M',
  },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardService, AdminGuardService],
  },
  {
    path: 'cgp',
    loadChildren: () => import('./cgp/cgp.module').then((m) => m.CgpModule),
    canActivate: [AuthGuardService, CompanyGuardService],
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService, CompanyGuardService, AdminGuardService],
})
export class AppRoutingModule {}
