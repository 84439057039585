import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CgpRoutingModule } from '@cgplib/cgp/cgp-routing.module';
import { ContactComponent } from '@cgplib/cgp/contact/contact.component';
import { DashboardComponent } from '@cgplib/cgp/dashboard/dashboard.component';
import { LeadsListComponent } from '@cgplib/cgp/leads-list/leads-list.component';
import { NavbarComponent } from '@cgplib/cgp/navbar/navbar.component';
import { HeaderInterceptor } from '@cgplib/interceptors/header.interceptor';
import { UserEntityService } from '@cgplib/services/user-entity.service';
import { SharedRoutingModule } from '@cgplib/shared/shared-routing.module';
import { SharedModule } from '@cgplib/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LeadsCSVGenerator } from './services/leadCSVGenerator.service';

@NgModule({
  declarations: [
    DashboardComponent,
    LeadsListComponent,
    ContactComponent,
    NavbarComponent,
    ContactComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CgpRoutingModule,
    SharedModule,
    SharedRoutingModule,
    NgbModule,
    NgApexchartsModule,
    EditorModule,
  ],
  exports: [NavbarComponent],
  providers: [
    UserEntityService,
    LeadsCSVGenerator,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
})
export class CgpModule {}
