<ng-template #confirmModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-center">Confirmation</h4>
	</div>
	<div class="modal-body">
		<p>
			Êtes-vous sur de vouloir supprimer cet élément ? <br>
			Cette opération est irréversible.
		</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="closeModal()">Annuler</button>	
		<button type="button" class="btn btn-danger" (click)="executeDelete()">Supprimer</button>
	</div>
</ng-template>