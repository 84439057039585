import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'Paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class PaginatorComponent implements OnInit, OnChanges {
  // public actualPage: number = 1;
  // public rows: number = 1;

  defaultPage: number = 1;
  defaultRows: number = 10;

  params!: Params;
  queryParams: {r: number, p: number} = {
    r: this.defaultRows,
    p: this.defaultPage
  };

  pages: number[] = [];
  numberOfPages: number = 0;

  @Input() totalRows: number = 0;
  @Input() fetchData: () => void = () => {};
  @Output() refreshData: EventEmitter<any> = new EventEmitter()

  constructor( 
    private route: ActivatedRoute,
    private router: Router) {
    }
    
    ngOnInit(): void {
    this.initQueryParams()
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.loadPagination()
  }
  
  // Query Params management
  initQueryParams() {
    this.route.queryParamMap.subscribe( params => {
      this.params = params;
      if(params.keys.length === 0) {
        this.updateRoute();
        this.refreshData.emit()
        return
      }
      this.queryParams = this.params["params"];
      this.checkParams()
      this.refreshData.emit()
    })
  }
  
  updateRoute() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
    this.loadPagination()
    this.checkParams()
  }
  
  checkParams() {
    if(this.queryParams.p == 0 || this.queryParams.r == 0 || this.queryParams.p > this.numberOfPages) {
      this.router.navigate(["not-found"]);
    }
  }

  // Pagination functions
  public loadPagination(): void {
    if(this.totalRows <= this.queryParams.r) {
      this.numberOfPages = 1;
      if(this.queryParams.p != 1) this.loadPage(1);
    } else {
      this.numberOfPages = Math.floor(this.totalRows / this.queryParams.r);
      if(this.numberOfPages * this.queryParams.r < this.totalRows) this.numberOfPages += 1;
    }
    this.pages = Array.from(new Array(this.numberOfPages), (x, i) => i + 1);
  }

  loadPage(page: number): void {
    this.queryParams = {...this.queryParams}
    this.queryParams.p = page;
    this.updateRoute();
    this.refreshData.emit()
  }

  previousPage() {
    this.queryParams = {...this.queryParams}
    this.queryParams.p = Number(this.queryParams.p) - 1;
    this.updateRoute();
    this.refreshData.emit()
  }

  nextPage() {
    this.queryParams = {...this.queryParams}
    this.queryParams.p = Number(this.queryParams.p) + 1;
    this.updateRoute();
    this.refreshData.emit()
  }

  public getQueryParams() {return this.queryParams}
}
