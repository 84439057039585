<ng-template #leadModal let-modal>
  <!-- Lead Modal -->
  <div *ngIf="openLead">
    <div class="modal-header">
      <h4 class="modal-title text-center">Fiche Prospect</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeModal()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="row mb-1" *ngIf="markLeadOpenError">
        <div class="col-12 text-danger">
          <div class="alert alert-danger" role="alert">
            Une erreur est survenue lors de la mise à jour du lead. Merci
            d'appuyer sur <span class="fw-bold">Marquer vu</span> pour essayer à
            nouveau.
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-6 col-sm-12 mb-1-sm">
          Nom: <strong>{{ lead.lastName }}</strong>
        </div>
        <div class="col-md-6 col-sm-12">
          Prénom: <strong>{{ lead.firstName }}</strong>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          Adresse mail: <strong>{{ lead.mail }}</strong>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          Téléphone: <strong>{{ lead.tel }}</strong>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          Message: <br /><strong>{{ lead.message }}</strong>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          Date de soumission:
          <strong>{{ lead.dateOfCreation | date : "dd/MM/yyy" }}</strong>
        </div>
      </div>
      <div class="row mb-1" *ngIf="lead.converted">
        <div class="col-12">
          Date de conversion:
          <strong>{{ lead.dateOfConversion | date : "dd/MM/yyy" }}</strong>
        </div>
      </div>
      <div class="row mb-1" *ngIf="lead.corrupted">
        <div class="col-12">
          Raison du signalement:
          <strong>{{ lead.corruptedMessage }}</strong>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="markLeadAsConverted()"
        *ngIf="!lead.converted"
        [disabled]="!user.isUserCompany()"
      >
        Convertir
      </button>
      <button
        [disabled]="!user.isUserCompany() || lead.corrupted"
        type="button"
        class="btn btn-danger"
        (click)="openErrorModal()"
      >
        Signaler
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="markLeadAsOpen()"
        *ngIf="markLeadOpenError"
      >
        Marquer vu
      </button>
    </div>
  </div>
</ng-template>

<ng-template #corruptionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Signaler un lead</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="row mb-3">
        <label for="reason">Raison du signalement:</label>
        <div class="col-sm-10">
          <!-- <textarea
            name="reportMessage"
            class="form-control"
            style="height: 100px"
            [formControl]="reportFC"
            [class.is-invalid]="reportFC.invalid && reportFC.dirty"
            placeholder="Rentrer la raison de votre signalement ici"
          >
          </textarea> -->
          <editor
            [formControl]="reportFC"
            [class.is-invalid]="reportFC.invalid && reportFC.dirty"
            [init]="{
              base_url: '/tinymce',
              suffix: '.min',
              plugins: 'link',
              height: 300
            }"
          ></editor>
          <div
            class="invalid-feedback"
            *ngIf="validatorService.hasRequiredError(reportFC)"
          >
            {{ errorsMessages["required"] }}
          </div>
          <div
            class="invalid-feedback"
            *ngIf="validatorService.hasMinLengthError(reportFC)"
          >
            {{ errorsMessages["notEnoughText"] }}
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="undoCorrupted()" class="btn btn-primary">
      Annuler
    </button>
    <button
      type="button"
      [disabled]="this.reportFC.invalid"
      class="btn btn-danger"
      (click)="openConfirmModal()"
    >
      Signaler
    </button>
  </div>
</ng-template>

<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Signaler un lead</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    Êtes-vous sur de vouloir signaler ce lead comme corrompu ?
  </div>
  <div class="modal-footer">
    <button type="button" (click)="openErrorModal()" class="btn btn-primary">
      Précédent
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="markLeadAsCorrupted()"
    >
      Confirmer
    </button>
  </div>
</ng-template>
