import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserEntityService } from '../../services/user-entity.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'LoadingPopin',
  templateUrl: './loading-popin.component.html',
  styleUrls: ['./loading-popin.component.scss'],
})
export class LoadingPopinComponent {
  @ViewChild('loadingModal') loadingModal: TemplateRef<any> | undefined;
  closeResult = '';

  constructor(
    private user: UserEntityService,
    public modalService: NgbModal,
  ) {
    this.user.setUser();
  }

  public openLoadingModal() {
    this.modalService.dismissAll();
    this.openModal(this.loadingModal);
  }

  openModal(content: any) {
    this.modalService
      .open(content, { size: 'md', centered: true, backdrop: 'static', scrollable: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public closeModal() {
    this.modalService.dismissAll();
  }

}
