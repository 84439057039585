<header id="header" class="header fixed-top d-flex align-items-center">
  <div class="d-flex align-items-center justify-content-between">
    <a href="index.html" class="logo d-flex align-items-center">
      <img src="assets/theme/img/logo.png" alt="" />
      <span class="d-none d-lg-block">Mon Courtier & Moi</span>
    </a>
    <i class="bi bi-list toggle-sidebar-btn" (click)="toggleSideBar()"></i>
  </div>

  <nav class="header-nav ms-auto">
    <ul class="d-flex align-items-center">
      <li class="nav-item pe-3">
        <div ngbDropdown class="nav-link nav-profile pe-0 pointer">
          <span
            class="ps-2"
            id="dropdownProfile"
            ngbDropdownToggle
            *ngIf="this.userProfile"
            >{{ this.userProfile.firstName[0] }}.
            {{ this.userProfile.lastName }}</span
          >
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownProfile"
            class="profile"
          >
            <div class="dropdown-header" *ngIf="this.userProfile">
              <h6>
                {{ this.userProfile.firstName }} {{ this.userProfile.lastName }}
              </h6>
              <span>{{ this.userProfile.idCompany.commercialName }}</span>
            </div>
            <hr class="dropdown-divider" />
            <a
              ngbDropdownItem
              class="dropdown-item d-flex align-items-center"
              routerLink="/profile"
            >
              <i class="bi bi-person"></i>
              <span class="dropdown-link-text">Mon profil</span>
            </a>
            <hr class="dropdown-divider" />
            <a
              ngbDropdownItem
              class="dropdown-item d-flex align-items-center"
              routerLink="/logout"
            >
              <i class="bi bi-box-arrow-right"></i>
              <span class="dropdown-link-text" routerLink="/logout"
                >Déconnexion</span
              >
            </a>
            <hr class="dropdown-divider" />
          </div>
        </div>
      </li>
    </ul>
  </nav>
</header>

<aside id="sidebar" class="sidebar">
  <ul class="sidebar-nav" id="sidebar-nav">
    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'dashboard'"
        routerLink="/cgp/dashboard"
      >
        <i class="bi bi-grid"></i>
        <span>Dashboard</span>
      </a>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'leads'"
        routerLink="/cgp/leads/list"
      >
        <i class="bi bi-person-plus"></i>
        <span>Leads</span>
        <span class="badge text-bg-secondary ms-auto">{{
          notOpenedLeads
        }}</span>
      </a>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink !== 'articles'"
        data-bs-target="#articles-nav"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="this.activeLink === 'articles'"
      >
        <i class="bi bi-newspaper"></i><span>Articles</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="articles-nav"
        class="nav-content collapse"
        [class.show]="this.activeLink === 'articles'"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a
            routerLink="/cgp/articles/list"
            [class.active]="this.activeDropdown === 'all'"
          >
            <i class="bi bi-circle"></i><span>Voir tous</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/cgp/articles/new"
            [class.active]="this.activeDropdown === 'publish'"
          >
            <i class="bi bi-circle"></i><span>Créer un article</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item" *ngIf="user.getLevel() === levels.PREMIUM" >
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink !== 'simulators'"
        data-bs-target="#simulators-nav"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="this.activeLink === 'simulators'"
      >
      <i class="bi bi-file-earmark-bar-graph"></i><span>Simulations</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="simulators-nav"
        class="nav-content collapse"
        [class.show]="this.activeLink === 'simulators'"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a
            routerLink="/cgp/simulators/own"
            [class.active]="this.activeDropdown === 'own'"
          >
            <i class="bi bi-circle"></i><span>Mes simulations</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/cgp/simulators/others"
            [class.active]="this.activeDropdown === 'others'"
          >
            <i class="bi bi-circle"></i><span>Simulations publiques</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink !== 'profile'"
        data-bs-target="#profile-nav"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="this.activeLink === 'profile'"
      >
        <i class="bi bi-person"></i><span>Profil</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="profile-nav"
        class="nav-content collapse"
        [class.show]="this.activeLink === 'profile'"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a
            routerLink="/cgp/profile"
            [class.active]="this.activeDropdown === 'infos'"
          >
            <i class="bi bi-circle"></i><span>Mes informations</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/cgp/profile/company"
            [class.active]="this.activeDropdown === 'company'"
          >
            <i class="bi bi-circle"></i><span>Mon entreprise</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/cgp/profile/transactions"
            [class.active]="this.activeDropdown === 'transactions'"
          >
            <i class="bi bi-circle"></i><span>Mes transactions</span>
          </a>
        </li>
        <li>
          <a
            routerLink="/cgp/profile/payments"
            [class.active]="this.activeDropdown === 'payments'"
          >
            <i class="bi bi-circle"></i><span>Mes paiments</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <a
        class="nav-link"
        [class.collapsed]="this.activeLink != 'contact'"
        routerLink="/cgp/contact"
      >
        <i class="bi bi-envelope"></i>
        <span>Nous contacter</span>
      </a>
    </li>
  </ul>
</aside>
