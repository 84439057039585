<main>
  <div class="container">
    <section
      class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center"
    >
      <h1>404</h1>
      <h2>La page que vous cherchez n'existe pas</h2>
      <button class="btn" (click)="redirect()">Revenir au site</button>
      <img
        src="assets/theme/img/not-found.svg"
        class="img-fluid py-5"
        alt="Page Not Found"
      />
    </section>
  </div>
</main>
