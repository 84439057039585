<Navbar activeLink="memberships"></Navbar>

<main id="main" class="main">
  <div class="pagetitle">
    <h1>Abonnements</h1>
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">
          <a routerLink="/admin/dashboard">Accueil</a>
        </li>
        <li class="breadcrumb-item">Abonnements</li>
      </ol>
    </nav>
  </div>

  <div class="section">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Abonnements</h5>

            <div class="search col-md-3 ms-auto">
              <div class="row g-3 justify-content-end">
                <div class="col-auto">
                  <button class="btn btn-primary" (click)="addMembershipModal.openAddMembershipModal()">Ajouter un abonnement</button>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Prix</th>
                    <th>Niveau</th>
                    <th>Périodicité</th>
                    <th>Identifiant stripe</th>
                    <th>Souscriptions actives</th>
                    <th>Total des souscriptions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody *ngIf="this.memberships">
                  <tr *ngFor="let membership of memberships">
                    <td>{{ membership.membership.name }}</td>
                    <td>
                      {{
                        membership.membership.priceBoarding | currency : "EUR"
                      }}
                    </td>
                    <td>{{ membership.membership.level }}</td>
                    <td>{{ membership.membership.period }}</td>
                    <td>{{ membership.membership.idProduct }}</td>
                    <td>{{ membership.activeSubs }}</td>
                    <td>{{ membership.totalSubs }}</td>
                    <td>
                      <button
                        *ngIf="membership.totalSubs === 0"
                        class="btn btn-danger"
                        (click)="showConfirmModal(membership.membership)"
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<ConfirmDeleteModal
  #confirmModal
  (confirmChange)="deleteMembership(selectedMembership)"
></ConfirmDeleteModal>
<AddMembershipModal #addMembershipModal (onCloseModal)="this.fetchAllMemberships()"></AddMembershipModal>
