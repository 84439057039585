import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DataTablesModule } from 'angular-datatables';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CgpRoutingModule } from './cgp/cgp-routing.module';
import { CgpModule } from './cgp/cgp.module';
import { CguComponent } from './cgu/cgu.component';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RegistrationModule } from './registration/registration.module';
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component';
import { UserEntityService } from './services/user-entity.service';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    CguComponent,
    LoginComponent,
    ForgotPwdComponent,
    ResetPwdComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    EditorModule,
    HttpClientModule,
    DataTablesModule,
    CgpModule,
    CgpRoutingModule,
    SharedModule,
    RegistrationModule,
    AdminModule
  ],
  providers: [
    UserEntityService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor() {
    registerLocaleData(localeFr);
  }
}
