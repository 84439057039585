import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../shared/services/toaster.service';
import { CompanyProfile } from '../../model/Companies';
import { RegistrationService } from '../services/registration.service';
import { INTERCEPTED_ERRORS } from '../../interceptors/header.interceptor';

@Component({
  selector: 'app-success-registration',
  templateUrl: './success-registration.component.html',
  styleUrls: ['./success-registration.component.scss'],
})
export class SuccessRegistrationComponent implements OnInit {
  id: string = '';
  company!: CompanyProfile;

  constructor(
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.registrationService.isSuccessRegistrationPossible(this.id).subscribe({
      next: (result: { legit: boolean }) => {
        if (!result.legit) this.router.navigate(['not-found']);
      },
      error: (error) => {
        if (!INTERCEPTED_ERRORS.includes(error.status))
          this.toasterService.showError(error.message);
      },
    });

    this.registrationService.fetchCompanyInfos(this.id).subscribe({
      next: (company: CompanyProfile) => {
        this.company = company;
        if (company.company === null) this.router.navigate(['not-found']);
      },
      error: (error) => {
        if (error.status === 404) {
          this.router.navigate(['not-found']);
          return;
        }
        if (!INTERCEPTED_ERRORS.includes(error.status))
          this.toasterService.showError(error.message);
      },
    });
  }

  ngOnInit(): void {}
}
