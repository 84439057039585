<main>
  <div class="container">
    <section
      class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center"
          >
            <div class="d-flex justify-content-center py-4">
              <a
                href="index.html"
                class="logo d-flex align-items-center w-auto"
              >
                <img src="../../assets/theme/img/logo.png" alt="" />
                <span class="d-none d-lg-block">Mon Courtier & Moi</span>
              </a>
            </div>

            <div class="card mb-3">
              <div class="card-body">
                <div class="pt-4 pb-2">
                  <h5 class="card-title text-center pb-0 fs-4">
                    Création de compte
                  </h5>
                  <p class="text-center small">
                    Saisissez vos informations pour finaliser votre inscription
                    au nom de
                    <strong *ngIf="this.company">{{
                      this.company.company.commercialName
                    }}</strong>
                  </p>
                </div>

                <form
                  [formGroup]="registrationForm"
                  class="row g-3"
                  (ngSubmit)="registerUser()"
                >
                  <div class="col-12">
                    <label for="lastName" class="form-label">Nom*</label>
                    <input
                      type="text"
                      formControlName="lastName"
                      class="form-control"
                      [class.is-invalid]="
                        validatorsService.hasRequiredError(
                          this.registrationForm.get('lastName')
                        )
                      "
                    />
                    <div class="invalid-feedback">
                      Ce champ est obligatoire.
                    </div>
                  </div>
                  <div class="col-12">
                    <label for="firstName" class="form-label">Prénom*</label>
                    <input
                      type="text"
                      formControlName="firstName"
                      class="form-control"
                      [class.is-invalid]="
                        validatorsService.hasRequiredError(
                          this.registrationForm.get('firstName')
                        )
                      "
                    />
                    <div class="invalid-feedback">
                      Ce champ est obligatoire.
                    </div>
                  </div>

                  <div class="col-12">
                    <label for="login" class="form-label">Adresse mail*</label>
                    <input
                      type="email"
                      formControlName="mail"
                      class="form-control"
                      [class.is-invalid]="
                        validatorsService.hasRequiredError(
                          this.registrationForm.get('mail')
                        ) ||
                        validatorsService.hasMailError(
                          this.registrationForm.get('mail')
                        )
                      "
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        validatorsService.hasRequiredError(
                          this.registrationForm.get('mail')
                        )
                      "
                    >
                      Ce champ est obligatoire.
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        validatorsService.hasMailError(
                          this.registrationForm.get('mail')
                        )
                      "
                    >
                      Le format est incorrect.
                    </div>
                  </div>

                  <div class="col-12">
                    <label for="pwd" class="form-label">Mot de passe*</label>
                    <div class="input-group">
                      <input
                        [type]="showPwdText ? 'text' : 'password'"
                        class="form-control"
                        formControlName="pwd"
                        (input)="updatePwdErrors($event)"
                        [class.is-invalid]="
                          validatorsService.hasRequiredError(
                            this.registrationForm.get('pwd')
                          ) ||
                          validatorsService.hasFormatPwdError(
                            this.registrationForm.get('pwd')
                          )
                        "
                      />
                      <button
                        class="btn btn-link-secondary"
                        type="button"
                        (click)="showPwd()"
                      >
                        <i
                          class="bi"
                          [class.bi-eye-slash]="showPwdText"
                          [class.bi-eye]="!showPwdText"
                        ></i>
                      </button>
                    </div>
                    <div
                      class="text-danger invalid-msg"
                      *ngIf="
                        validatorsService.hasRequiredError(
                          this.registrationForm.get('pwd')
                        )
                      "
                    >
                      Ce champ est obligatoire.
                    </div>
                    <div
                      class="invalid-msg"
                      [class.text-danger]="
                        validatorsService.hasFormatPwdError(
                          this.registrationForm.get('pwd')
                        )
                      "
                    >
                      Votre mot de passe doit contenir :
                      <ul>
                        <li [class.text-success]="pwdMatch.length">
                          Au moins 10 caractères
                        </li>
                        <li [class.text-success]="pwdMatch.maj">
                          Au moins 1 majuscule
                        </li>
                        <li [class.text-success]="pwdMatch.num">
                          Au moins 1 chiffre
                        </li>
                        <li [class.text-success]="pwdMatch.special">
                          Au moins 1 caractère spécial (* . ! @ $ % ^ & : ; , .
                          ?)
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-12">
                    <label for="pwd" class="form-label"
                      >Confirmer le mot de passe*</label
                    >
                    <div class="input-group">
                      <input
                        [type]="showConfirmText ? 'text' : 'password'"
                        class="form-control"
                        formControlName="confirm"
                        [class.is-invalid]="
                          validatorsService.hasRequiredError(
                            this.registrationForm.get('confirm')
                          ) ||
                          validatorsService.hasConfirmError(
                            this.registrationForm
                          )
                        "
                      />
                      <button
                        class="btn btn-link-secondary"
                        type="button"
                        (click)="showConfirm()"
                      >
                        <i
                          class="bi"
                          [class.bi-eye-slash]="showConfirmText"
                          [class.bi-eye]="!showConfirmText"
                        ></i>
                      </button>
                    </div>
                    <div
                      class="text-danger invalid-msg"
                      *ngIf="
                        validatorsService.hasRequiredError(
                          this.registrationForm.get('confirm')
                        )
                      "
                    >
                      Ce champ est obligatoire.
                    </div>
                    <div
                      class="text-danger invalid-msg"
                      *ngIf="
                        validatorsService.hasConfirmError(this.registrationForm)
                      "
                    >
                      Les mots de passe ne correspondent pas.
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        formControlName="consent"
                        type="checkbox"
                        [class.is-invalid]="
                          validatorsService.hasRequiredError(
                            this.registrationForm.get('consent')
                          )
                        "
                      />
                      <label
                        class="form-check-label"
                        for="acceptTerms"
                        [class.text-danger]="
                          validatorsService.hasRequiredError(
                            this.registrationForm.get('consent')
                          )
                        "
                        >J'ai lu et j'accepte
                        <a href="/cgu" target="_blank"
                          >les conditions générales</a
                        >*</label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      class="btn btn-outline-primary w-100 mb-2"
                      type="button"
                      (click)="loadDemoData()"
                    >
                      Charger les données démo
                    </button>

                    <button
                      class="btn btn-primary w-100"
                      type="submit"
                      [disabled]="!this.registrationForm.valid"
                    >
                      Créer mon compte
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
