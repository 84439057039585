import { MembershipsService } from './../services/memberships.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserEntityService } from '../../services/user-entity.service';
import { Membership } from '../../model/Membership';
import { ToasterService } from '../../shared/services/toaster.service';
import { INTERCEPTED_ERRORS } from '../../interceptors/header.interceptor';
import { ConfirmDeleteModalComponent } from '../../shared/confirm-delete/confirm-delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMembershipModalComponent } from './add-membership-modal/add-membership-modal.component';

@Component({
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss'],
})
export class MembershipsComponent implements OnInit {
  memberships: {
    membership: Membership;
    activeSubs: number;
    totalSubs: number;
  }[] = [];
  selected!: Membership;

  @ViewChild('addMembershipModal') addMembershipModal!: AddMembershipModalComponent;
  @ViewChild('confirmModal') confirmModal!: ConfirmDeleteModalComponent;
  selectedMembership!: Membership;

  constructor(
    private user: UserEntityService,
    private service: MembershipsService,
    private toasterService: ToasterService,
    private modalService: NgbModal
  ) {
    this.user.setUser();
  }

  ngOnInit(): void {
    this.fetchAllMemberships();
  }

  fetchAllMemberships() {
    this.service.fetchMemberships().subscribe({
      next: (memberships) => {
        this.memberships = memberships;
      },
      error: (error) => {
        this.toasterService.showError(error.error.message);
      },
    });
  }

  showConfirmModal(membership: Membership) {
    this.selectedMembership = membership;
    this.confirmModal.openConfirmModal();
  }

  deleteMembership(membership: Membership) {
    this.service.deleteMembership(membership._id).subscribe({
      next: () => {
        this.toasterService.showSuccess('Abonnement supprimé avec succès!');
        this.fetchAllMemberships();
        this.modalService.dismissAll()
      },
      error: (error: any) => {
        if (!INTERCEPTED_ERRORS.includes(error.status))
          this.toasterService.showError(error.error.message);
      },
    });
  }
}
