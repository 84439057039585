import { Injectable } from '@angular/core';
import { Lead } from '../../model/Lead';
import { DatesService } from '../../shared/services/dates.service';

@Injectable({
  providedIn: 'root',
})
export class LeadsCSVGenerator {
  constructor(
    private datesServices: DatesService,
  ) {}

  generateCSV(leads: Lead[]): string {
    const formattedLeads = this.formatLeads(leads);
    const csvContent = this.formatToCSV(formattedLeads);

    return csvContent;
  }

  private formatLeads(leads: Lead[]): string[][] {
    const selectedAttributes: string[] = [
      'Prénom',
      'Nom',
      'Adresse mail',
      'Téléphone',
      'Conversion',
      'Date de création',
    ];
    const formatedLeads = [selectedAttributes];
    this.sortLeadsByConversionAndName(leads);
    leads.forEach((lead) => {
      const formated = this.formatLead(lead);
      formatedLeads.push(formated);
    });

    return formatedLeads;
  }

  private formatLead(lead: Lead) {
    const formattedDate = this.datesServices.formatDateAlt(lead.dateOfCreation);
    const convertedFormated = this.datesServices.formatDateAlt(lead.dateOfConversion);
    const row = [
      lead.firstName,
      lead.lastName,
      lead.mail,
      lead.tel,
      formattedDate,
      convertedFormated,
    ];
    return row;
  }

  private sortLeadsByConversionAndName(leads: Lead[]): void {
    leads.sort((a, b) => {
      // Sort by conversion status (converted) in descending order (`true` values ​​are placed before `false` values)
      if (b.converted !== a.converted) {
        return b.converted ? 1 : -1;
      }

      // If the conversion status is the same, sort by last name (firstName) and first name (lastName) in ascending order
      const nameComparison = a.firstName.localeCompare(b.firstName);
      if (nameComparison !== 0) {
        return nameComparison;
      }

      return a.lastName.localeCompare(b.lastName);
    });
  }

  private formatToCSV(leads: string[][]): string {
    return leads.map((row) => row.join(',')).join('\n');
  }
}
