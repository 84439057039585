import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyProfile } from '../model/Companies';
import { RegistrationService } from './services/registration.service';
import { ValidatorsService } from '../services/validators.service';
import { ToasterService } from '../shared/services/toaster.service';
import { INTERCEPTED_ERRORS } from '../interceptors/header.interceptor';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  id: string = '';
  company!: CompanyProfile;

  registrationForm: UntypedFormGroup = new UntypedFormGroup(
    {
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      mail: new UntypedFormControl(null, [Validators.required, Validators.email]),
      pwd: new UntypedFormControl(null, [
        Validators.required,
        this.validatorsService.pwdFormatValidator,
      ]),
      confirm: new UntypedFormControl(null, [Validators.required]),
      consent: new UntypedFormControl(false, [
        Validators.required,
        this.validatorsService.consentValidator,
      ]),
    },
    [this.validatorsService.matchValidator('pwd', 'confirm')]
  );

  pwdMatch = {
    maj: false,
    num: false,
    special: false,
    length: false,
  };

  showPwdText: boolean = false;
  showConfirmText: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    public validatorsService: ValidatorsService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.registrationService.isRegistrationNeeded(this.id).subscribe({
      next: (result: { registered: boolean }) => {
        if (result.registered) this.router.navigate(['login']);
      },
      error: (err) => {
        if (!INTERCEPTED_ERRORS.includes(err.status))
          this.toasterService.showError(err.message);
      },
    });

    this.registrationService.fetchCompanyInfos(this.id).subscribe({
      next: (company: CompanyProfile) => {
        this.company = company;
        if (company.company === null) this.router.navigate(['not-found']);
      },
      error: (error) => {
        if (error.status === 404) {
          this.router.navigate(['not-found']);
          return;
        }
        if (!INTERCEPTED_ERRORS.includes(error.status))
          this.toasterService.showError(error.message);
      },
    });
  }

  ngOnInit(): void {}

  registerUser() {
    if (this.registrationForm.invalid) return;
    this.registrationService
      .registerUser(this.id, this.registrationForm.value)
      .subscribe({
        next: () => {
          this.router.navigate(['registration', this.id, 'success']);
        },
        error: (error) => {
          if (!INTERCEPTED_ERRORS.includes(error.status))
            this.toasterService.showError(error.message);
        },
      });
  }

  // Form functions

  showPwd() {
    this.showPwdText = !this.showPwdText;
  }

  showConfirm() {
    this.showConfirmText = !this.showConfirmText;
  }

  updatePwdErrors(event: any) {
    const pwd: string = this.registrationForm.get('pwd')!.value as string;
    this.pwdMatch = {
      maj: pwd.match(this.validatorsService.PWD_MAJ_REGEX) !== null,
      num: pwd.match(this.validatorsService.PWD_NUM_REGEX) !== null,
      special: pwd.match(this.validatorsService.PWD_SPECIAL_REGEX) !== null,
      length: pwd.match(this.validatorsService.PWD_LENGTH_REGEX) !== null,
    };
  }

  loadDemoData() {
    this.registrationForm.patchValue({
      lastName: 'Guenoun',
      firstName: 'Ruben',
      mail: 'ruben.guenoun@cyje.fr',
      pwd: 'Userp@ss2812',
      confirm: 'Userp@ss2812',
      consent: true,
    });
  }

}
