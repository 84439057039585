<ng-template #addMembershipModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Ajouter un abonnement</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="membershipForm">
      <div class="mb-3">
        <label for="name" class="form-label">Nom</label>
        <input
          type="text"
          class="form-control"
          formControlName="name"
          [class.is-invalid]="
            validatorsService.hasRequiredError(
              this.membershipForm.get('name')
            ) ||
            validatorsService.hasMinLengthError(this.membershipForm.get('name'))
          "
        />
        <div
          class="invalid-feedback"
          *ngIf="
            validatorsService.hasRequiredError(this.membershipForm.get('name'))
          "
        >
          {{ errorsMessages["required"] }}
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            validatorsService.hasMinLengthError(this.membershipForm.get('name'))
          "
        >
          {{ errorsMessages["minlength"] }}
        </div>
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">Prix (en €)</label>
        <input
          type="number"
          class="form-control"
          formControlName="priceBoarding"
          [class.is-invalid]="
            validatorsService.hasRequiredError(
              this.membershipForm.get('priceBoarding')
            )
          "
        />
        <div
          class="invalid-feedback"
          *ngIf="
            validatorsService.hasRequiredError(
              this.membershipForm.get('priceBoarding')
            )
          "
        >
          {{ errorsMessages["required"] }}
        </div>
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">Niveau</label>
        <select type="number" class="form-select" formControlName="level">
          <option [value]="levels.PREMIUM">Premium</option>
          <option [value]="levels.STANDARD">Standard</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">Périodicité</label>
        <select type="number" class="form-select" formControlName="period">
          <option [value]="periods.MONTHLY">Mensuel</option>

          <option [value]="periods.YEARLY">Annuel</option>
        </select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" (click)="addMembership()">
      Ajouter
    </button>
  </div>
</ng-template>
