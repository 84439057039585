import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  auth(data: {login: string, pwd: string, rememberMe: boolean}) {
    return this.http.post(`${environment.API_URL}/users/login`, data).pipe();
  }

  sendResetPwdEmail(email: string) {
    return this.http.post(`${environment.API_URL}/users/send-pwd-email`, {email}).pipe()
  }

  resetPwd(data: {token: string, pwd: string}) {
    return this.http.post(`${environment.API_URL}/users/reset-pwd`, data).pipe()
  }
}
