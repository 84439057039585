import { UserEntityService } from './user-entity.service';
import { Injectable } from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

@Injectable()
export class AdminGuardService implements CanActivate {

  constructor(
    private router: Router,
    private user: UserEntityService
  ) {
    this.user.setUser();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.user.isUserAdmin()) {
      return true;
    } else {
      this.router.navigate(['/not-found']);
      return false;
    }
  }

}