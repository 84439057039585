import { Company } from "./Companies";

export enum MembershipType {
  PREMIUM = 'PREMIUM',
  STANDARD = 'STANDARD',
  CUSTOM = 'Custom',
}

export enum MembershipLevel {
	PREMIUM = "PREMIUM",
	STANDARD = "STANDARD",
}

export enum MembershipPeriods {
	YEARLY = "YEARLY",
	MONTHLY = "MONTHLY",
}

export interface Membership {
	_id?: string;
	name: string;
	priceBoarding: number;
	priceLead: number;
	giftLead: number;
	priceRedirection: number;
	giftRedirection: number;
	expirationDate: Date;
	level: string;
	stripeProductId: string;
	stripePriceId: string;
	period: MembershipPeriods;
	idProduct ?: string;
}

export interface Payments {
  _id: string;
  idMembership: string;
  date: Date;
  ammount: Number;
  yearOfMembership: Date;
}

export interface Subscription {
	_id?: string;
	idMembership?: string;
	activate: boolean;
	dateOfActivation: Date;
	dateOfExpiration: Date;
}

export interface MembershipPopulatedSubscription {
	_id?: string;
	idMembership: Membership;
	idCompany: string,
	activate: boolean;
	dateOfActivation: Date;
	dateOfExpiration: Date;
}

export interface PopulatedSubscription {
	_id?: string;
	idMembership: Membership;
	idCompany: Company,
	activate: boolean;
	dateOfActivation: Date;
	dateOfExpiration: Date;
}