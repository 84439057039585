import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Membership, MembershipLevel, MembershipPeriods } from '../../model/Membership';

@Injectable({
  providedIn: 'root'
})
export class MembershipsService {

  constructor(private http: HttpClient) { }

  fetchMemberships() {
    return this.http.get<{membership: Membership, activeSubs: number, totalSubs: number}[]>(`${environment.API_URL}/membership/all`);
  }

  deleteMembership(id: string | undefined) {
    return this.http.delete(`${environment.API_URL}/membership/delete/${id}`);
  }

  addMembership(data: {name: string, priceBoarding: number, level: MembershipLevel, period: MembershipPeriods}) {
    return this.http.post(`${environment.API_URL}/membership/add`, data);
  }
}
